var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleBlur },
              model: {
                value: _vm.form.typeId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "typeId", $$v)
                },
                expression: "form.typeId"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "散货", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "拖车", name: "2" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "70px",
                    model: _vm.form
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提货地址" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入提货地址" },
                        on: { blur: _vm.handleBlur, change: _vm.handleBlur },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBlur.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.pick,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "pick", $$v)
                          },
                          expression: "form.pick"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.typeId == "1"
                            ? "送货地址"
                            : _vm.form.typeId == "2"
                            ? "起运港"
                            : ""
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        on: { blur: _vm.handleBlur, change: _vm.handleBlur },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBlur.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.dispatch,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dispatch", $$v)
                          },
                          expression: "form.dispatch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "成交状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.dealTypeId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dealTypeId", $$v)
                            },
                            expression: "form.dealTypeId"
                          }
                        },
                        _vm._l(_vm.optionList.dealList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户", "label-width": "45px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入客户" },
                        on: { blur: _vm.handleBlur, change: _vm.handleBlur },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBlur.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customer", $$v)
                          },
                          expression: "form.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "产品专员" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.productCommissionerId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productCommissionerId", $$v)
                            },
                            expression: "form.productCommissionerId"
                          }
                        },
                        _vm._l(_vm.optionList.productCommissionerList, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "业务员" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.salesmanId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "salesmanId", $$v)
                            },
                            expression: "form.salesmanId"
                          }
                        },
                        _vm._l(_vm.optionList.salesmanList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "日期" }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions,
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "unlink-panels": "",
                          "value-format": "yyyy-MM-dd"
                        },
                        on: { change: _vm.pickerClick },
                        model: {
                          value: _vm.beginTime,
                          callback: function($$v) {
                            _vm.beginTime = $$v
                          },
                          expression: "beginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handleFold }
                        },
                        [
                          _vm.fold
                            ? _c("span", [_vm._v("展开")])
                            : _c("span", [_vm._v("合并")]),
                          _c("vab-icon", {
                            staticClass: "vab-dropdown",
                            class: { "vab-dropdown-active": _vm.fold },
                            attrs: { icon: "arrow-up-s-line" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleAdd("", "add")
                    }
                  }
                },
                [_vm._v(" 新建 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 导出 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.form.typeId == "1"
        ? _c(
            "div",
            [
              _c("bulk-cargo", {
                ref: "tableOne",
                attrs: { form: _vm.form, "handle-add": _vm.handleAdd },
                on: { customEvent: _vm.handleCustomEvent }
              })
            ],
            1
          )
        : _vm.form.typeId == "2"
        ? _c(
            "div",
            [
              _c("Trailer", {
                ref: "tableTwo",
                attrs: { form: _vm.form, "handle-add": _vm.handleAdd },
                on: { customEvent: _vm.handleCustomEvent }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("WholeVehicle", {
                ref: "tableThree",
                attrs: { form: _vm.form, "handle-add": _vm.handleAdd },
                on: { customEvent: _vm.handleCustomEvent }
              })
            ],
            1
          ),
      _c("BulkCargoEdit", {
        ref: "edit1",
        attrs: { "option-list": _vm.optionList },
        on: {
          change: function($event) {
            return _vm.queryList($event)
          }
        }
      }),
      _c("TrailerEdit", {
        ref: "edit2",
        attrs: { "option-list": _vm.optionList },
        on: {
          change: function($event) {
            return _vm.queryList($event)
          }
        }
      }),
      _c("WholeVehicleEdit", {
        ref: "edit3",
        attrs: { "option-list": _vm.optionList },
        on: {
          change: function($event) {
            return _vm.queryList($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }