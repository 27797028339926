<template>
  <div>
    <div style="height: calc(100vh - 330px)">
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        element-loading-text="拼命加载中"
        border
        :data="list"
        height="100%"
        @selection-change="setSelectRows"
        header-cell-class-name="table-header"
        class="table eleChange"
        :row-style="{ height: '30px' }"
        :cell-style="{ padding: '2px' }"
        :row-class-name="rowStyle"
        @row-click="handleRowClick"
      >
        <el-table-column
          align="center"
          show-overflow-tooltip
          fixed="left"
          type="selection"
          width="55"
        />
        <el-table-column label="询价编号" align="center" prop="inquiryCode" />
        <el-table-column label="基础信息" align="center">
          <el-table-column
            prop="createTime"
            align="center"
            label="日期"
          ></el-table-column>
          <el-table-column
            prop="customer"
            align="center"
            label="客户"
          ></el-table-column>
          <el-table-column
            prop="salesman"
            align="center"
            label="业务员"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="提货地址" align="center">
          <el-table-column prop="inquiryPickList" align="center" label="起运市">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryPickList"
                :key="index"
                style="text-align: center"
              >
                {{ item.city }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="inquiryPickList" align="center" label="起运区">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryPickList"
                :key="index"
                style="text-align: center"
              >
                {{ item.areas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="100px"
            prop="inquiryPickList"
            align="center"
            label="起运街道"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryPickList"
                :key="index"
                style="text-align: center"
              >
                {{ item.street }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDispatchList"
            align="center"
            label="具体地址"
            width="220px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryPickList"
                :key="index"
                style="text-align: center"
              >
                {{ item.address }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="送货地址" align="center">
          <el-table-column
            prop="inquiryDispatchPortList"
            align="center"
            label="起运港"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDispatchPortList"
                :key="index"
                style="text-align: center"
              >
                {{ item.portName }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="货物信息" align="center">
          <el-table-column
            prop="inquiryGoodsList"
            align="center"
            label="柜型柜量"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryGoodsList"
                :key="index"
                style="text-align: center"
              >
                {{ item.cabinetType }}*{{ item.cabinetNum }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="weight"
            align="center"
            label="重量"
          ></el-table-column>
          <!-- <el-table-column
            prop="volume"
            align="center"
            label="体积"
          ></el-table-column> -->
        </el-table-column>
        <el-table-column
          label="备注"
          width="350"
          show-overflow-tooltip
          align="center"
          prop="remark"
        >
          <template slot="header">
            备注
            <el-tooltip effect="dark" content="询价信息的补充" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="报价信息" align="center">
          <el-table-column
            label="产品编号"
            align="center"
            class-name="myCell"
            prop="inquiryDetailList"
            width="120"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.productCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            class-name="myCell"
            align="center"
            label="产品专员"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.productCommissioner }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            class-name="myCell"
            align="center"
            label="起运区"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.productPickAreas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            class-name="myCell"
            align="center"
            label="起运街道"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.productPickStreet }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="总标准价"
            class-name="myCell"
          >
            <template slot="header">
              总标准价
              <el-tooltip effect="dark" content="产品报价" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.productQuotation }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="总销售价"
            class-name="myCell"
          >
            <template slot="header">
              总销售价
              <el-tooltip effect="dark" content="客户报价" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.customerQuotation }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="成交状态"
            class-name="myCell"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                <span v-if="item.dealType == '未成交'">
                  <el-tag type="danger">{{ item.dealType }}</el-tag>
                </span>
                <span v-if="item.dealType == '已成交'">
                  <el-tag>{{ item.dealType }}</el-tag>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="物流方案"
            class-name="myCell"
            width="90px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.logisticsType }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="标准价明细"
            class-name="myCell"
            width="300px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                <el-tooltip
                  :content="item.standardDesc"
                  placement="top"
                  effect="dark"
                >
                  <span
                    style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    "
                  >
                    {{ item.standardDesc }}
                  </span>
                </el-tooltip>
                <!-- {{
                '20GP:' +
                item.standard_20gp +
                '; ' +
                '40GP:' +
                item.standard_40gp +
                '; ' +
                '40HQ:' +
                item.standard_40hq +
                '; ' +
                '45HQ:' +
                item.standard_45hq +
                '; ' +
                ''
              }} -->
                <!-- {{ item.standardDesc }} -->
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="是否含税"
            class-name="myCell"
            width="90px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{
                  item.includingTaxes == 0
                    ? '不含税'
                    : item.includingTaxes == 1
                    ? '含税'
                    : ''
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="时效"
            class-name="myCell"
            width="90px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.ageing }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="附加费"
            class-name="myCell"
            width="90px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                {{ item.surcharge }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="inquiryDetailList"
            align="center"
            label="备注"
            class-name="myCell"
            width="200px"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.inquiryDetailList"
                :key="index"
                style="text-align: center"
                class="myCellDiv"
              >
                <el-tooltip
                  :content="item.remark"
                  placement="top"
                  effect="dark"
                >
                  <span
                    style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    "
                  >
                    {{ item.remark }}
                  </span>
                </el-tooltip>
                <!-- <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                v-if="!isShowTooltip"
              >
                <div v-html="ToBreak(item.remark, 50)" slot="content"></div>
                <div class="oneLine" @mouseover="onMouseOver">
                  {{ item.remark }}
                </div>
              </el-tooltip>
              <div
                class="oneLine"
                @mouseover="onMouseOver"
                v-if="isShowTooltip"
              >
                {{ item.remark }}
              </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" class-name="myCell" fixed="right" label="询价来源" width="100">
          <template slot-scope="scope">
            {{ scope.row.sourceType == 1 ? 'web端询价' : '手机端询价' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="成交操作"
          fixed="right"
          width="100"
          class-name="myCell"
        >
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.inquiryDetailList"
              :key="index"
              style="text-align: center"
              class="myCellDiv"
            >
              <el-button
                type="text"
                v-if="item.dealTypeId == 6"
                @click="handleDiao(item.id, 'typeOne')"
                style="padding: 0px"
              >
                变更未成交
              </el-button>
              <el-button
                type="text"
                v-else
                @click="handleDiao(item.id, 'typeTwo')"
                style="padding: 0px; color: red"
              >
                变更已成交
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createBy"
          align="center"
          label="创建人"
          fixed="right"
          width="60"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="70" fixed="right">
          <template #default="{ row }">
            <el-button type="text" @click="handleUpdata(row, 'update')">
              修改
            </el-button>
            <el-button type="text" @click="handleDelete(row)">删除</el-button>
            <el-button type="text" @click="handleUpdata(row, 'copy')">
              复制
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import {
  bulkCargoCarList,
  bulkCargoCarDelete,
  bulkCargoCarUpdateDetailStatus,
  bulkCargoCarCheckDetailStatus,
} from '@/api/inquiryManagement'
export default {
  props: {
    form: Object,
    handleAdd: Function,
  },
  data() {
    return {
      listLoading: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      height: this.$baseTableHeight(2) - 50,
      list: [],
      idList: [],
      isShowTooltip: true,
      ToBreak(val, num) {
        console.log(val)
        console.log(num)
        var str = val
        var number = num //间隔换行的字符数
        var bytesCount = 0 //定义一个变量记录字符串总字节长度
        var s = '' //定义一个空字符串用来接收重新拼接换行之后的字符串
        var pattern = new RegExp('[\u4E00-\u9FA5]+') //汉字的正则表达式
        for (var i = 0; i < str.length; i++) {
          //遍历原字符串
          var c = str.charAt(i) //拿到每一个下标对应的值
          // 统计字符串的字符长度
          if (pattern.test(c)) {
            bytesCount += 2 //如果是汉字长度+2
          } else {
            bytesCount += 1 //不是汉字长度+1
          }
          s += str.charAt(i) //重新拼接字符串
          // 换行
          if (bytesCount >= number) {
            //在指定的长度位置插入换行标签
            s = s + '<br>'
            // 重置
            bytesCount = 0 //保证在下一个相同长度后继续换行
          }
        }
        return s
      },
      queryForm: {
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  mounted() {
    // this.handleQuery()
  },
  beforeMount() {
    window.addEventListener('resize', this.handleHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleHeight)
  },
  methods: {
    handleHeight() {
      this.height = this.$baseTableHeight(3) - 30
    },
    onMouseOver(e) {
      console.log(e)
      var target = e.target
      let textLength = target.clientWidth
      let containerLength = target.scrollWidth
      console.log(textLength)
      console.log(containerLength)
      if (textLength < containerLength) {
        // 溢出了
        this.isShowTooltip = false
      } else {
        this.isShowTooltip = true
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = val
      this.handleQuery()
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.handleQuery()
    },
    setSelectRows(val) {
      this.idList = []
      for (let index = 0; index < val.length; index++) {
        this.idList.push(val[index].id)
      }
      this.$emit('customEvent', this.idList)
    },
    rowStyle({ row }) {
      var arr = this.idList
      for (let i = 0; i < arr.length; i++) {
        if (row.id === arr[i]) {
          return 'rowStyle'
        }
      }
    },
    handleRowClick(row, column) {
      if (column && column.label == '操作') {
        return
      }
      this.$refs.tableSort.toggleRowSelection(row)
    },
    async handleQuery() {
      this.listLoading = true
      this.$set(this.form, 'pageNum', this.queryForm.pageNum)
      this.$set(this.form, 'pageSize', this.queryForm.pageSize)
      const res = await bulkCargoCarList(this.form)
      this.list = res.data.pageList
      this.total = res.data.totalSize
      this.listLoading = false
    },
    handleUpdata(row, type) {
      this.handleAdd(row, type)
      this.$refs.tableSort.toggleRowSelection(row, true)
      // this.$refs['edit'].showEdit(row)
    },
    // async handleDiao(row, type) {
    //   let data = {
    //     id: row,
    //     dealTypeId: 7,
    //     dealType: '未成交',
    //   }
    //   if (type == 'typeOne') {
    //     const res = await bulkCargoCarUpdateDetailStatus(data)
    //     this.handleQuery()
    //     this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
    //   } else {
    //     this.$set(data, 'dealTypeId', 6)
    //     this.$set(data, 'dealType', '已成交')
    //     const res = await bulkCargoCarUpdateDetailStatus(data)
    //     this.handleQuery()
    //     this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
    //   }
    // },
    async handleDiao(row, type) {
      let data = {
        id: row,
        dealTypeId: 7,
        dealType: '未成交',
      }
      if (type == 'typeOne') {
        const rew = await bulkCargoCarCheckDetailStatus(data)
        console.log(rew)
        if (rew.data) {
          const res = await bulkCargoCarUpdateDetailStatus(data)
          this.handleQuery()
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } else {
          this.$baseConfirm(
            '已有成交报价!若确认该报价已成交，则原已成交的报价会变为未成交',
            null,
            async () => {
              const res = await bulkCargoCarUpdateDetailStatus(data)
              this.handleQuery()
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            }
          )
        }
      } else {
        this.$set(data, 'dealTypeId', 6)
        this.$set(data, 'dealType', '已成交')
        const rew = await bulkCargoCarCheckDetailStatus(data)
        if (rew.data) {
          const res = await bulkCargoCarUpdateDetailStatus(data)
          this.handleQuery()
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } else {
          this.$baseConfirm(
            '已有成交报价!若确认该报价已成交，则原已成交的报价会变为未成交',
            null,
            async () => {
              const res = await bulkCargoCarUpdateDetailStatus(data)
              this.handleQuery()
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            }
          )
        }
        // const res = await bulkCargoCarUpdateDetailStatus(data)
        // this.handleQuery()
        // this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
      }
    },
    async handleDelete(row) {
      this.$refs.tableSort.toggleRowSelection(row, true)
      this.$baseConfirm('确认删除此条询价吗', '警告', async () => {
        const res = await bulkCargoCarDelete({ id: row.id })
        this.handleQuery()
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .cell {
    padding: 0px;
  }
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.myCell:not(.table-header),
.myCell:not(.table-header) .cell {
  padding: unset !important;
}
.myCell .myCellDiv {
  border-bottom: 1px solid #ebeef5;
  // padding: 20px 12px;
  height: 60px;
  // display: table-cell;
  // text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.myCell .cell .myCellDiv:last-child {
  border-bottom: unset;
}
::v-deep .rowStyle {
  background-color: #e8f7fd !important;
  color: #1890ff;
}
</style>
