var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "1000px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticStyle: { float: "left" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    },
                    [_vm._v("继续创建下一条")]
                  )
                ],
                1
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "bt" }, [_vm._v("拖车")]),
      _c(
        "div",
        { staticClass: "conte" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                model: _vm.form,
                rules: _vm.rules,
                inline: true
              }
            },
            [
              _c(
                "div",
                { staticClass: "conte1" },
                [
                  _c("div", { staticClass: "conte-bt" }, [_vm._v("询价信息")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户", prop: "customer" } },
                    [
                      _c("el-autocomplete", {
                        staticClass: "inline-input",
                        attrs: {
                          "fetch-suggestions": _vm.querySearchKe,
                          placeholder: "请输入内容",
                          clearable: ""
                        },
                        on: { select: _vm.handleSelectKe },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", [
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.form.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customer", $$v)
                          },
                          expression: "form.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员", prop: "salesmanId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            width: "150px"
                          },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.onSelectSalesman },
                          model: {
                            value: _vm.form.salesmanId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "salesmanId", $$v)
                            },
                            expression: "form.salesmanId"
                          }
                        },
                        _vm._l(_vm.optionList.salesmanList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "inquiryPickList" } },
                    _vm._l(_vm.form.inquiryPickList, function(domain, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "conte-input" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    display: "inline"
                                  }
                                },
                                [
                                  _vm._v(" 提货地址 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning"
                                      })
                                    ]
                                  ),
                                  _c("el-autocomplete", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      "popper-class": "my-autocomplete",
                                      "fetch-suggestions": _vm.querySearch,
                                      placeholder: "输入待解析的地址",
                                      "popper-append-to-body": false
                                    },
                                    on: {
                                      select: function($event) {
                                        return _vm.handleSelect($event, index)
                                      },
                                      change: function($event) {
                                        return _vm.hendQuery(domain, index)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#409eff"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    "font-size": "10px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.cityName) +
                                                      _vm._s(item.areasName) +
                                                      "    " +
                                                      _vm._s(item.address) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("span", {
                                                staticClass: "addr"
                                              }),
                                              _c("span", {
                                                staticClass: "addr"
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: domain.place,
                                      callback: function($$v) {
                                        _vm.$set(domain, "place", $$v)
                                      },
                                      expression: "domain.place"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    margin: "0px 10px",
                                    width: "180px"
                                  },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    "filter-method": _vm.areasFilterOne
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onSelectAreasList(
                                        domain.uqKey,
                                        index
                                      )
                                    }
                                  },
                                  model: {
                                    value: domain.uqKey,
                                    callback: function($$v) {
                                      _vm.$set(domain, "uqKey", $$v)
                                    },
                                    expression: "domain.uqKey"
                                  }
                                },
                                _vm._l(_vm.areasListOne, function(item) {
                                  return _c("el-option", {
                                    key: item.uqKey,
                                    attrs: {
                                      label: item.fullName,
                                      value: item.uqKey
                                    }
                                  })
                                }),
                                1
                              ),
                              _c("el-input", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "详细地址",
                                  clearable: "",
                                  maxlength: "100"
                                },
                                model: {
                                  value: domain.address,
                                  callback: function($$v) {
                                    _vm.$set(domain, "address", $$v)
                                  },
                                  expression: "domain.address"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "an" }, [
                            _c(
                              "span",
                              [
                                _c("vab-icon", {
                                  attrs: { icon: "add-box-fill" },
                                  on: { click: _vm.addInquiryPickList }
                                })
                              ],
                              1
                            ),
                            index != 0
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "160"
                                        },
                                        model: {
                                          value: domain.visible,
                                          callback: function($$v) {
                                            _vm.$set(domain, "visible", $$v)
                                          },
                                          expression: "domain.visible"
                                        }
                                      },
                                      [
                                        _c("p", [_vm._v("确定删除吗？")]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                              margin: "0"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "text"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    domain.visible = false
                                                  }
                                                }
                                              },
                                              [_vm._v(" 取消 ")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteInquiryPickList(
                                                      domain
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" 确定 ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("vab-icon", {
                                          attrs: {
                                            slot: "reference",
                                            icon: "checkbox-indeterminate-line"
                                          },
                                          slot: "reference"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._l(_vm.form.inquiryDispatchPortList, function(
                    domain,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: domain.id, staticClass: "conte-input" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "送货地址",
                              prop:
                                "inquiryDispatchPortList." + index + ".portId",
                              rules: [
                                {
                                  required: true,
                                  trigger: "change",
                                  message: "请选择送货地址"
                                }
                              ]
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectpPort(domain.portId)
                                  }
                                },
                                model: {
                                  value: domain.portId,
                                  callback: function($$v) {
                                    _vm.$set(domain, "portId", $$v)
                                  },
                                  expression: "domain.portId"
                                }
                              },
                              _vm._l(_vm.optionList.portList, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.form.inquiryGoodsList, function(domain, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "conte-input" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "柜型",
                              prop:
                                "inquiryGoodsList." + index + ".cabinetTypeId",
                              rules: [
                                {
                                  required: true,
                                  trigger: "change",
                                  message: "请选择柜型"
                                }
                              ]
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "130px"
                                },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelectAreasListCabinet(
                                      domain.cabinetTypeId,
                                      index
                                    )
                                  }
                                },
                                model: {
                                  value: domain.cabinetTypeId,
                                  callback: function($$v) {
                                    _vm.$set(domain, "cabinetTypeId", $$v)
                                  },
                                  expression: "domain.cabinetTypeId"
                                }
                              },
                              _vm._l(_vm.optionList.cabinetList, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "柜量",
                              prop: "inquiryGoodsList." + index + ".cabinetNum",
                              rules: [
                                {
                                  required: true,
                                  trigger: "blur",
                                  message: "请输入柜量"
                                }
                              ]
                            }
                          },
                          [
                            _c("el-input-number", {
                              attrs: { min: 1, max: 1000, label: "描述文字" },
                              model: {
                                value: domain.cabinetNum,
                                callback: function($$v) {
                                  _vm.$set(domain, "cabinetNum", $$v)
                                },
                                expression: "domain.cabinetNum"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "an",
                            staticStyle: { "padding-top": "6px" }
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("vab-icon", {
                                  attrs: { icon: "add-box-fill" },
                                  on: { click: _vm.addInquiryGoodsList }
                                })
                              ],
                              1
                            ),
                            index != 0
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "160"
                                        },
                                        model: {
                                          value: domain.visible,
                                          callback: function($$v) {
                                            _vm.$set(domain, "visible", $$v)
                                          },
                                          expression: "domain.visible"
                                        }
                                      },
                                      [
                                        _c("p", [_vm._v("确定删除吗？")]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                              margin: "0"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "text"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    domain.visible = false
                                                  }
                                                }
                                              },
                                              [_vm._v(" 取消 ")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteInquiryGoodsList(
                                                      domain
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" 确定 ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("vab-icon", {
                                          attrs: {
                                            slot: "reference",
                                            icon: "checkbox-indeterminate-line"
                                          },
                                          slot: "reference"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "重量", prop: "weight" } },
                    [
                      _c("div", { staticClass: "fomrDisply" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  slot: "reference",
                                  clearable: "",
                                  oninput:
                                    "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,3})?.*$/,'$1')"
                                },
                                on: {
                                  blur: function($event) {
                                    _vm.form.weight = $event.target.value
                                  }
                                },
                                slot: "reference",
                                model: {
                                  value: _vm.form.weight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "weight",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.weight"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v(" T  ")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "padding-left": "10px" } },
                          [
                            _c("el-input", {
                              staticClass: "input-with-select",
                              staticStyle: { width: "400px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请输入数字和运算符，乘号只能用*",
                                oninput:
                                  "value=value.replace(/[^0-9+().*%/-]/g,'')"
                              },
                              on: {
                                blur: function($event) {
                                  _vm.form.originalWeight = $event.target.value
                                }
                              },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.inputChangeOne.apply(
                                    null,
                                    arguments
                                  )
                                }
                              },
                              model: {
                                value: _vm.form.originalWeight,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "originalWeight",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.originalWeight"
                              }
                            }),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "80px" },
                                attrs: {
                                  slot: "prepend",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.inputChangeOne },
                                slot: "prepend",
                                model: {
                                  value: _vm.form.originalWeightUnit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "originalWeightUnit",
                                      $$v
                                    )
                                  },
                                  expression: "form.originalWeightUnit"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "KG", value: "KG" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "T", value: "T" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "650px" },
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "200",
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._l(_vm.form.inquiryDetailList, function(domain, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "conte1" },
                  [
                    _c("div", { staticClass: "conte1-disp" }, [
                      _c("div", { staticClass: "conte-bt" }, [
                        _vm._v("报价信息")
                      ]),
                      _c("div", { staticClass: "an" }, [
                        _c(
                          "span",
                          [
                            _c("vab-icon", {
                              attrs: { icon: "add-box-fill" },
                              on: { click: _vm.addInquiryDetailList }
                            })
                          ],
                          1
                        ),
                        index != 0
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: { placement: "right", width: "160" },
                                    model: {
                                      value: domain.visible,
                                      callback: function($$v) {
                                        _vm.$set(domain, "visible", $$v)
                                      },
                                      expression: "domain.visible"
                                    }
                                  },
                                  [
                                    _c("p", [_vm._v("确定删除吗？")]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          margin: "0"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "text"
                                            },
                                            on: {
                                              click: function($event) {
                                                domain.visible = false
                                              }
                                            }
                                          },
                                          [_vm._v(" 取消 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteInquiryDetailList(
                                                  domain
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" 确定 ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("vab-icon", {
                                      attrs: {
                                        slot: "reference",
                                        icon: "checkbox-indeterminate-line"
                                      },
                                      slot: "reference"
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "提货地址:",
                              prop: "inquiryDetailList"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: { clearable: "", disabled: "" },
                              model: {
                                value: domain.addPickAreas,
                                callback: function($$v) {
                                  _vm.$set(
                                    domain,
                                    "addPickAreas",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "domain.addPickAreas"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "产品专员", prop: "inquiryDetailList" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              width: "130px"
                            },
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: {
                              change: function($event) {
                                return _vm.onSelectAreasListThree(
                                  domain.productCommissionerId,
                                  index
                                )
                              }
                            },
                            model: {
                              value: domain.productCommissionerId,
                              callback: function($$v) {
                                _vm.$set(domain, "productCommissionerId", $$v)
                              },
                              expression: "domain.productCommissionerId"
                            }
                          },
                          _vm._l(
                            _vm.optionList.productCommissionerList,
                            function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }
                          ),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "inquiryDetailList" } },
                      [
                        _vm._v(" 总标准价 "),
                        _c(
                          "el-tooltip",
                          {
                            staticStyle: { "padding-right": "6px" },
                            attrs: {
                              effect: "dark",
                              content: "产品报价",
                              placement: "top"
                            }
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "总标准价",
                            clearable: "",
                            maxlength: "12",
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.productQuotation = $event.target.value
                            }
                          },
                          model: {
                            value: domain.productQuotation,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "productQuotation",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.productQuotation"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "inquiryDetailList" } },
                      [
                        _vm._v(" 总销售价 "),
                        _c(
                          "el-tooltip",
                          {
                            staticStyle: { "padding-right": "6px" },
                            attrs: {
                              effect: "dark",
                              content: "客户报价",
                              placement: "top"
                            }
                          },
                          [_c("i", { staticClass: "el-icon-warning" })]
                        ),
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "总销售价",
                            clearable: "",
                            maxlength: "12",
                            disabled: domain.dealTypeId == 6,
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.customerQuotation = $event.target.value
                            }
                          },
                          model: {
                            value: domain.customerQuotation,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "customerQuotation",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.customerQuotation"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "成交状态", prop: "inquiryDetailList" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              width: "130px"
                            },
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: {
                              change: function($event) {
                                return _vm.onSelectAreasListFour(
                                  domain.dealTypeId,
                                  index
                                )
                              }
                            },
                            model: {
                              value: domain.dealTypeId,
                              callback: function($$v) {
                                _vm.$set(domain, "dealTypeId", $$v)
                              },
                              expression: "domain.dealTypeId"
                            }
                          },
                          _vm._l(_vm.optionList.dealList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "物流方案", prop: "inquiryDetailList" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              width: "130px"
                            },
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: {
                              change: function($event) {
                                return _vm.onSelectAreasListFive(
                                  domain.logisticsTypeId,
                                  index
                                )
                              }
                            },
                            model: {
                              value: domain.logisticsTypeId,
                              callback: function($$v) {
                                _vm.$set(domain, "logisticsTypeId", $$v)
                              },
                              expression: "domain.logisticsTypeId"
                            }
                          },
                          _vm._l(_vm.optionList.carList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "时效", prop: "inquiryDetailList" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "时效",
                            maxlength: "12",
                            clearable: ""
                          },
                          model: {
                            value: domain.ageing,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "ageing",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.ageing"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "是否含税", prop: "inquiryDetailList" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              "margin-right": "10px",
                              width: "130px"
                            },
                            attrs: { filterable: "", placeholder: "请选择" },
                            model: {
                              value: domain.includingTaxes,
                              callback: function($$v) {
                                _vm.$set(domain, "includingTaxes", $$v)
                              },
                              expression: "domain.includingTaxes"
                            }
                          },
                          _vm._l(_vm.optionList.taxList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("h5", [_vm._v("标准价明细")]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "20GP", prop: "inquiryDetailList" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "20GP",
                            maxlength: "12",
                            clearable: "",
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.standard_20gp = $event.target.value
                            }
                          },
                          model: {
                            value: domain.standard_20gp,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "standard_20gp",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.standard_20gp"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "40GP", prop: "inquiryDetailList" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "40GP",
                            maxlength: "12",
                            clearable: "",
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.standard_40gp = $event.target.value
                            }
                          },
                          model: {
                            value: domain.standard_40gp,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "standard_40gp",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.standard_40gp"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "40HQ", prop: "inquiryDetailList" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "40HQ",
                            maxlength: "12",
                            clearable: "",
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.standard_40hq = $event.target.value
                            }
                          },
                          model: {
                            value: domain.standard_40hq,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "standard_40hq",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.standard_40hq"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "45HQ", prop: "inquiryDetailList" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "130px" },
                          attrs: {
                            placeholder: "45HQ",
                            maxlength: "12",
                            clearable: "",
                            oninput:
                              "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')"
                          },
                          on: {
                            blur: function($event) {
                              domain.standard_45hq = $event.target.value
                            }
                          },
                          model: {
                            value: domain.standard_45hq,
                            callback: function($$v) {
                              _vm.$set(
                                domain,
                                "standard_45hq",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "domain.standard_45hq"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          inline: false,
                          label: "附加费",
                          prop: "inquiryDetailList"
                        }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "650px" },
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            maxlength: "200",
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.surcharge,
                            callback: function($$v) {
                              _vm.$set(domain, "surcharge", $$v)
                            },
                            expression: "domain.surcharge"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          inline: false,
                          label: "备注",
                          prop: "inquiryDetailList"
                        }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "650px" },
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            maxlength: "200",
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: domain.remark,
                            callback: function($$v) {
                              _vm.$set(domain, "remark", $$v)
                            },
                            expression: "domain.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }