<template>
  <el-dialog
    class="dialog"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="1000px"
    @close="close"
  >
    <div class="bt">散货</div>
    <div class="conte">
      <el-form
        ref="form"
        :inline="true"
        label-width="80px"
        :model="form"
        :rules="rules"
      >
        <div class="conte1">
          <div class="conte-bt">询价信息</div>
          <el-form-item label="客户" prop="customer">
            <el-autocomplete
              v-model="form.customer"
              class="inline-input"
              clearable
              :fetch-suggestions="querySearchKe"
              placeholder="请输入内容"
              @select="handleSelectKe"
            >
              <template slot-scope="{ item }">
                <div>
                  {{ item.name }}
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="业务员" prop="salesmanId">
            <el-select
              v-model="form.salesmanId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 150px"
              @change="onSelectSalesman"
            >
              <el-option
                v-for="item in optionList.salesmanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="inquiryPickList">
            <div
              v-for="(domain, index) in form.inquiryPickList"
              :key="index"
              class="conte-input"
            >
              <div>
                <div style="margin-left: 5px; display: inline">
                  提货地址
                  <el-tooltip
                    class="item"
                    content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                    effect="dark"
                    placement="top"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                  <el-autocomplete
                    v-model="domain.place"
                    :fetch-suggestions="querySearch"
                    placeholder="输入待解析的地址"
                    :popper-append-to-body="false"
                    popper-class="my-autocomplete"
                    style="width: 300px"
                    @change="hendQuery(domain, index)"
                    @select="handleSelect($event, index)"
                  >
                    <template slot-scope="{ item }">
                      <div style="color: #409eff">
                        {{ item.name }}
                      </div>
                      <div class="name" style="font-size: 10px">
                        {{ item.cityName
                        }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                          item.address
                        }}
                      </div>
                      <span class="addr"></span>
                      <span class="addr"></span>
                    </template>
                  </el-autocomplete>
                </div>
                <el-select
                  v-model="domain.uqKey"
                  :filter-method="areasFilterOne"
                  filterable
                  placeholder="请选择"
                  style="margin: 0px 10px; width: 180px"
                  @change="onSelectAreasList(domain.uqKey, index)"
                >
                  <el-option
                    v-for="item in areasListOne"
                    :key="item.uqKey"
                    :label="item.fullName"
                    :value="item.uqKey"
                  />
                </el-select>
                <!-- <el-popover
                  placement="bottom"
                  width="160"
                  v-model="domain.visibleThree"
                >
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visibleThree = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="placeClickOne(domain, index)"
                    >
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
                <el-input
                  v-model="domain.address"
                  clearable
                  maxlength="100"
                  placeholder="详细地址"
                  style="width: 250px"
                />
              </div>
              <div class="an">
                <span>
                  <vab-icon icon="add-box-fill" @click="addInquiryPickList" />
                </span>
                <span v-if="index != 0">
                  <el-popover
                    v-model="domain.visible"
                    placement="right"
                    width="160"
                  >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="domain.visible = false"
                      >
                        取消
                      </el-button>
                      <el-button
                        size="mini"
                        type="primary"
                        @click="deleteInquiryPickList(domain)"
                      >
                        确定
                      </el-button>
                    </div>
                    <vab-icon
                      slot="reference"
                      icon="checkbox-indeterminate-line"
                    />
                  </el-popover>
                </span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" prop="inquiryDispatchList">
            <div
              v-for="(domain, index) in form.inquiryDispatchList"
              :key="index"
              class="conte-input"
            >
              <div>
                <div style="margin-left: 5px; display: inline">
                  送货地址
                  <el-tooltip
                    class="item"
                    content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                    effect="dark"
                    placement="top"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                  <el-autocomplete
                    v-model="domain.place"
                    :fetch-suggestions="querySearchTwo"
                    placeholder="输入待解析的地址"
                    :popper-append-to-body="false"
                    popper-class="my-autocomplete"
                    style="width: 300px"
                    @change="hendQueryTwo(domain, index)"
                    @select="handleSelectTwo($event, index)"
                  >
                    <template slot-scope="{ item }">
                      <div style="color: #409eff">
                        {{ item.name }}
                      </div>
                      <div class="name" style="font-size: 10px">
                        {{ item.cityName
                        }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                          item.address
                        }}
                      </div>
                      <span class="addr"></span>
                      <span class="addr"></span>
                    </template>
                  </el-autocomplete>
                </div>
                <el-select
                  v-model="domain.uqKey"
                  :filter-method="areasFilterTwo"
                  filterable
                  placeholder="请选择"
                  style="margin: 0px 10px; width: 180px"
                  @change="onSelectAreasListTwo(domain.uqKey, index)"
                >
                  <el-option
                    v-for="item in areasListTwo"
                    :key="item.uqKey"
                    :label="item.fullName"
                    :value="item.uqKey"
                  />
                </el-select>
                <!-- <el-popover
                  placement="bottom"
                  width="160"
                  v-model="domain.visibleFour"
                >
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visibleFour = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="placeClickTwo(domain, index)"
                    >
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
                <el-input
                  v-model="domain.address"
                  clearable
                  maxlength="100"
                  placeholder="详细地址"
                  style="width: 250px"
                />
              </div>
              <div class="an">
                <span>
                  <vab-icon
                    icon="add-box-fill"
                    @click="addInquiryDispatchList"
                  />
                </span>
                <span v-if="index != 0">
                  <el-popover
                    v-model="domain.visible"
                    placement="right"
                    width="160"
                  >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="domain.visible = false"
                      >
                        取消
                      </el-button>
                      <el-button
                        size="mini"
                        type="primary"
                        @click="deleteInquiryDispatchList(domain)"
                      >
                        确定
                      </el-button>
                    </div>
                    <vab-icon
                      slot="reference"
                      icon="checkbox-indeterminate-line"
                    />
                  </el-popover>
                </span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="重量" prop="weight">
            <div class="fomrDisply">
              <div>
                <el-input
                  v-model.trim="form.weight"
                  clearable
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
                  style="width: 200px"
                  @blur="form.weight = $event.target.value"
                  slot="reference"
                >
                  <template slot="append">&nbsp;KG&nbsp;&nbsp;</template>
                </el-input>
                <!-- <el-popover placement="bottom" width="160" v-model="visible">
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">
                      取消
                    </el-button>
                    <el-button type="primary" size="mini" @click="weightData()">
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
              </div>
              <div style="padding-left: 10px">
                <el-input
                  v-model.trim="form.originalWeight"
                  class="input-with-select"
                  clearable
                  oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
                  placeholder="请输入数字和运算符，乘号只能用*"
                  style="width: 400px"
                  @blur="form.originalWeight = $event.target.value"
                  @keyup.enter.native="inputChangeOne"
                />
                <el-select
                  slot="prepend"
                  v-model="form.originalWeightUnit"
                  placeholder="请选择"
                  style="width: 80px"
                  @change="inputChangeOne"
                >
                  <el-option label="KG" value="KG" />
                  <el-option label="T" value="T" />
                </el-select>
              </div>
            </div>
            <!-- <el-input
              placeholder="重量"
              v-model.trim="form.weight"
              clearable
              maxlength="100"
              style="width: 120px"
            /> -->
          </el-form-item>
          <el-form-item label="体积" prop="volume">
            <div class="fomrDisply">
              <div>
                <el-input
                  v-model.trim="form.volume"
                  clearable
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
                  style="width: 200px"
                  @blur="form.volume = $event.target.value"
                  slot="reference"
                >
                  <template slot="append">CBM</template>
                </el-input>
                <!-- <el-popover placement="bottom" width="160" v-model="visibleTwo">
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="visibleTwo = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="weightDataTwo()"
                    >
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
              </div>
              <div style="padding-left: 10px">
                <el-input
                  v-model.trim="form.originalVolume"
                  class="input-with-select"
                  clearable
                  oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
                  placeholder="请输入数字和运算符，乘号只能用*"
                  style="width: 400px"
                  @blur="form.originalVolume = $event.target.value"
                  @keyup.enter.native="inputChangeTwo"
                />
                <el-select
                  slot="prepend"
                  v-model="form.originalVolumeUnit"
                  placeholder="请选择"
                  style="width: 80px"
                  @change="inputChangeTwo"
                >
                  <el-option label="CBM" value="CBM" />
                  <el-option label="M" value="M" />
                  <el-option label="CM" value="CM" />
                  <el-option label="MM" value="MM" />
                </el-select>
              </div>
            </div>
            <!-- <el-input
              placeholder="体积"
              maxlength="100"
              v-model.trim="form.volume"
              clearable
              style="width: 120px"
            /> -->
          </el-form-item>
          <el-form-item label="包装方式" prop="packagingMethodId">
            <el-select
              v-model="form.packagingMethodId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px"
              @change="onSelectPackagin"
            >
              <el-option
                v-for="item in optionList.packageList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              maxlength="200"
              placeholder="请输入内容"
              :rows="3"
              style="width: 650px"
              type="textarea"
            />
          </el-form-item>
        </div>
        <div
          v-for="(domain, index) in form.inquiryDetailList"
          :key="index"
          class="conte1"
        >
          <div class="conte1-disp">
            <div class="conte-bt">报价信息</div>
            <div class="an">
              <span>
                <vab-icon icon="add-box-fill" @click="addInquiryDetailList" />
              </span>
              <span v-if="index != 0">
                <el-popover
                  v-model="domain.visible"
                  placement="right"
                  width="160"
                >
                  <p>确定删除吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visible = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      size="mini"
                      type="primary"
                      @click="deleteInquiryDetailList(domain)"
                    >
                      确定
                    </el-button>
                  </div>
                  <vab-icon
                    slot="reference"
                    icon="checkbox-indeterminate-line"
                  />
                </el-popover>
              </span>
            </div>
          </div>
          <div>
            <el-form-item label="提货地址:" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.addPickAreas"
                clearable
                disabled
                style="width: 300px"
              />
            </el-form-item>
            <el-form-item label="送货地址" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.addDispatch"
                clearable
                disabled
                style="width: 300px"
              />
            </el-form-item>
          </div>
          <el-form-item label="产品专员" prop="inquiryDetailList">
            <el-select
              v-model="domain.productCommissionerId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="
                onSelectAreasListThree(domain.productCommissionerId, index)
              "
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <!-- <el-input v-model.trim="domain.title" style="width: 130px" /> -->
          </el-form-item>
          <el-form-item label="" prop="inquiryDetailList">
            总标准价
            <el-tooltip
              content="产品报价"
              effect="dark"
              placement="top"
              style="padding-right: 6px"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-input
              v-model.trim="domain.productQuotation"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="总标准价"
              style="width: 130px"
              @blur="domain.productQuotation = $event.target.value"
            />
          </el-form-item>
          <el-form-item label="" prop="inquiryDetailList">
            总销售价
            <el-tooltip
              content="客户报价"
              effect="dark"
              placement="top"
              style="padding-right: 6px"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-input
              v-model.trim="domain.customerQuotation"
              clearable
              :disabled="domain.dealTypeId == 6"
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="总销售价"
              style="width: 130px"
              @blur="domain.customerQuotation = $event.target.value"
            />
          </el-form-item>
          <el-form-item label="成交状态" prop="inquiryDetailList">
            <el-select
              v-model="domain.dealTypeId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="onSelectAreasListFour(domain.dealTypeId, index)"
            >
              <el-option
                v-for="item in optionList.dealList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="物流方案" prop="inquiryDetailList">
            <el-select
              v-model="domain.logisticsTypeId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="onSelectAreasListFive(domain.logisticsTypeId, index)"
            >
              <el-option
                v-for="item in optionList.distList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="总公里数" prop="inquiryDetailList">
            <el-input
              v-model.trim="domain.logisticsDistance"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="总公里数（Km）"
              style="width: 130px"
              @blur="domain.logisticsDistance = $event.target.value"
            />
          </el-form-item>
          <el-form-item label="车型" prop="inquiryDetailList">
            <el-input
              v-model.trim="domain.truckType"
              clearable
              maxlength="12"
              placeholder="车型"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="时效" prop="inquiryDetailList">
            <el-input
              v-model.trim="domain.ageing"
              clearable
              maxlength="12"
              placeholder="时效"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="是否含税" prop="inquiryDetailList">
            <el-select
              v-model="domain.includingTaxes"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
            >
              <el-option
                v-for="item in optionList.taxList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <div v-if="domain.logisticsTypeId == '9'">
            <h5>标准价明细</h5>
            <el-form-item label="提货" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.standardPick"
                clearable
                maxlength="12"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                placeholder="提货"
                style="width: 130px"
                @blur="domain.standardPick = $event.target.value"
              />
            </el-form-item>
            <el-form-item label="干线" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.standardTrunk"
                clearable
                maxlength="12"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                placeholder="干线"
                style="width: 130px"
                @blur="domain.standardTrunk = $event.target.value"
              />
            </el-form-item>
            <el-form-item label="送货" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.standardDispatch"
                clearable
                maxlength="12"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                placeholder="送货"
                style="width: 130px"
                @blur="domain.standardDispatch = $event.target.value"
              />
            </el-form-item>
            <el-form-item label="风控" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.standardRiskManage"
                clearable
                maxlength="12"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                placeholder="风控"
                style="width: 130px"
                @blur="domain.standardRiskManage = $event.target.value"
              />
            </el-form-item>
          </div>
          <!-- <el-form-item :inline="false" label="附加费" prop="inquiryDetailList">
            <el-input
              type="textarea"
              :rows="3"
              maxlength="200"
              placeholder="请输入内容"
              v-model="domain.surcharge"
              style="width: 650px"
            ></el-input>
          </el-form-item> -->
          <el-form-item :inline="false" label="备注" prop="inquiryDetailList">
            <el-input
              v-model="domain.remark"
              maxlength="200"
              placeholder="请输入内容"
              :rows="3"
              style="width: 650px"
              type="textarea"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div style="float: left">
        <el-checkbox v-model="checked">继续创建下一条</el-checkbox>
      </div>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
  areasPlaceSuggestion,
  bulkCargoCopy,
  bulkCargoEdit,
  bulkCargoGetById,
  bulkCargoSave,
} from '@/api/inquiryManagement';
  export default {
    name: 'TableEdit',
    props: {
      optionList: Object,
    },
    data() {
      return {
        visible: false,
        visibleTwo: false,
        placeObject: {}, //解析地址
        placeObjectTwo: {}, //解析地址
        areasListOne: [], //提货地址
        areasListTwo: [], //送货地址
        placeListOne: [],
        placeListTwo: [],
        cbFun: null,
        form: {
          typeId: '1',
          typeName: '散货',
          customer: '', //客户
          salesmanId: '', //业务员id
          salesman: '', //业务员
          weight: '', //重量
          originalWeight: '', //重量公式
          originalWeightUnit: 'KG', //重量单位
          originalVolume: '', //体积公式
          originalVolumeUnit: 'CBM', //体积单位
          volume: '', //体积
          packagingMethodId: '', //包装方式id
          packagingMethod: '', //包装方式名称
          remark: '', //备注
          inquiryPickList: [
            {
              typeId: '1',
              typeName: '散货',
              place: '',
              provinceId: '',
              province: '',
              cityId: '',
              city: '',
              areas: '',
              areasId: '',
              address: '',
              street: '',
              streetId: '',
              uqKey: '',
              visible: false,
              visibleThree: false,
            },
          ],
          inquiryDispatchList: [
            {
              place: '',
              province: '',
              provinceId: '',
              city: '',
              cityId: '',
              areas: '',
              areasId: '',
              address: '',
              street: '',
              streetId: '',
              uqKey: '',
              visible: false,
              visibleFour: false,
            },
          ],
          inquiryDetailList: [
            {
              typeId: '1',
              typeName: '散货',
              productCommissionerId: '', //产品专员id
              productCommissioner: '', //产品专员名称
              productQuotation: '', //产品报价
              customerQuotation: '', //客户报价
              dealTypeId: 7, //成交状态id
              dealType: '未成交', //成交状态名称
              logisticsTypeId: '', //物流方案id
              logisticsType: '', //物流方案名称
              remark: '', //备注
              visible: false,
              ageing: '', //时效
              logisticsDistance: '', //总公里数（Km）
              truckType: '', //车型
              includingTaxes: 1, //是否含税
              standardPick: '', //标准价提货
              standardTrunk: '', //标准价干线
              standardDispatch: '', //标准价送货
              standardRiskManage: '', //标准价风控
              // surcharge: '', //附加费
              productPickAreas: '',
              productPickStreet: '',
              productPickKey: '',
              addPickAreas: '',
              productDispatchAreas: '',
              productDispatchStreet: '',
              productDispatchKey: '',
              addDispatch: '',
            },
          ],
        },
        checked: false,
        optionsOne: [],
        rules: {
          // customer: [
          //   { required: true, trigger: 'blur', message: '请输入客户' },
          //   { required: true, trigger: 'change', message: '请输入客户' },
          // ],
          // salesmanId: [
          //   { required: true, trigger: 'change', message: '请选择业务员' },
          // ],
          weight: [
            { required: true, trigger: 'blur', message: '请输入重量' },
            { required: true, trigger: 'change', message: '请输入重量' },
          ],
          volume: [
            { required: true, trigger: 'blur', message: '请输入体积' },
            { required: true, trigger: 'change', message: '请输入体积' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        bulkEdit: '',
      }
    },
    created() {},
    methods: {
      querySearchKe(queryString, cb) {
        var restaurants = this.optionList.costomerGetValidList
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results)
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
          )
        }
      },
      handleSelectKe(val) {
        this.form.customer = val.name
      },
      areasFilterOne(query = '') {
        let arr = this.optionList.areasList.filter((item) => {
          return item.fullName.includes(query) || item.fullName.includes(query)
        })
        if (arr.length > 150) {
          this.areasListOne = arr.slice(0, 150)
        } else {
          this.areasListOne = arr
        }
      },
      addValueOptions(areasId = '') {
        const targetList = this.optionList.areasList.filter((item) => {
          return item.areasId === areasId
        })
        this.areasListOne = targetList
        // 添加到下拉列表中
        const target = this.optionList.areasList.find((item) => {
          return item.areasId === areasId
        })
        if (target) {
          // 将当前条与小option比对，没有则加入
          if (
            this.areasListOne.every((item) => item.areasId !== target.areasId)
          ) {
            this.areasListOne.unshift(target)
          }
        }
      },
      areasFilterTwo(query = '') {
        let arr = this.optionList.areasList.filter((item) => {
          return item.fullName.includes(query) || item.fullName.includes(query)
        })
        if (arr.length > 150) {
          this.areasListTwo = arr.slice(0, 150)
        } else {
          this.areasListTwo = arr
        }
      },
      addValueOptionsTwo(areasId) {
        const targetList = this.optionList.areasList.filter((item) => {
          return item.areasId === areasId
        })
        this.areasListTwo = targetList
        // 添加到下拉列表中
        const target = this.optionList.areasList.find((item) => {
          return item.areasId === areasId
        })
        if (target) {
          // 将当前条与小option比对，没有则加入
          if (
            this.areasListTwo.every((item) => item.areasId !== target.areasId)
          ) {
            this.areasListTwo.unshift(target)
          }
        }
      },
      //提货地址解析
      async querySearch(queryString, cb) {
        this.cbFun = cb
        var restaurants = this.placeListOne
        cb(restaurants)
      },

      placeClickOne(domain, index) {
        this.placeAnalysis(this.placeObject, index)
        this.form.inquiryPickList[index].visibleThree = false
      },

      handleSelect(list, index) {
        console.log(list)
        this.$set(list, 'place', this.placeObject.place)
        this.placeObject = list
        // if (this.form.inquiryPickList[index].uqKey == '') {
        this.placeAnalysis(list, index)
        // } else {
        // this.form.inquiryPickList[index].visibleThree = true
        // }
      },
      placeAnalysis(list, index) {
        var data = this.optionList.areasList.find(
          (item) => item.uqKey == list.uqKey
        )
        this.addValueOptions(list.areasId)
        // this.areasFilterOne(list.areasName)
        this.form.inquiryPickList[index].provinceId = data.provinceId
        this.form.inquiryPickList[index].province = data.provinceName
        this.form.inquiryPickList[index].cityId = data.cityId
        this.form.inquiryPickList[index].city = data.cityName
        this.form.inquiryPickList[index].areas = data.areasName
        this.form.inquiryPickList[index].areasId = data.areasId
        this.form.inquiryPickList[index].uqKey = data.uqKey
        this.form.inquiryPickList[index].streetId = data.streetId
        this.form.inquiryPickList[index].street = data.streetName
        this.form.inquiryPickList[index].place = this.placeObject.place
        this.form.inquiryPickList[index].address =
          list.address + '(' + list.name + ')'
      },
      async hendQuery(domain, index) {
        this.placeObject.place = domain.place
        const res = await areasPlaceSuggestion({ place: domain.place })
        this.placeListOne = res.data
        this.querySearch(domain, this.cbFun)
      },

      //送货地址解析
      async querySearchTwo(queryString, cb) {
        this.cbFun = cb
        // const res = await areasPlaceSuggestion({ place: queryString })
        // this.placeListTwo = res.data
        var restaurants = this.placeListTwo
        cb(restaurants)
      },
      placeClickTwo(domain, index) {
        this.placeAnalysisTwo(this.placeObjectTwo, index)
        this.form.inquiryDispatchList[index].visibleFour = false
      },

      placeAnalysisTwo(list, index) {
        var data = this.optionList.areasList.find(
          (item) => item.uqKey == list.uqKey
        )
        this.addValueOptionsTwo(list.areasId)
        // this.areasFilterTwo(list.areasName)
        this.form.inquiryDispatchList[index].provinceId = data.provinceId
        this.form.inquiryDispatchList[index].province = data.provinceName
        this.form.inquiryDispatchList[index].cityId = data.cityId
        this.form.inquiryDispatchList[index].city = data.cityName
        this.form.inquiryDispatchList[index].areas = data.areasName
        this.form.inquiryDispatchList[index].areasId = data.areasId
        this.form.inquiryDispatchList[index].streetId = data.streetId
        this.form.inquiryDispatchList[index].street = data.streetName
        this.form.inquiryDispatchList[index].uqKey = data.uqKey
        this.form.inquiryDispatchList[index].place = this.placeObjectTwo.place
        this.form.inquiryDispatchList[index].address =
          list.address + '(' + list.name + ')'
      },
      handleSelectTwo(list, index) {
        this.$set(list, 'place', this.placeObjectTwo.place)
        this.placeObjectTwo = list
        // if (this.form.inquiryDispatchList[index].uqKey == '') {
        this.placeAnalysisTwo(list, index)
        // } else {
        // this.form.inquiryDispatchList[index].visibleFour = true
        // }
      },
      async hendQueryTwo(domain, index) {
        this.placeObjectTwo.place = domain.place
        const res = await areasPlaceSuggestion({ place: domain.place })
        this.placeListTwo = res.data
        this.querySearchTwo(domain, this.cbFun)
      },

      async showEdit(row, type) {
        this.areasFilterOne()
        this.areasFilterTwo()
        this.bulkEdit = type
        if (row) {
          if (type == 'update') {
            const res = await bulkCargoGetById({ id: row.id })
            res.data.inquiryPickList.forEach((one) => {
              this.addValueOptions(one.areasId)
            })
            res.data.inquiryDispatchList.forEach((one) => {
              this.addValueOptionsTwo(one.areasId)
            })
            this.form = Object.assign({}, res.data)
            this.addPick(res.data)
            // for (let i = 0; i < res.data.inquiryDetailList.length; i++) {
            //   this.form.inquiryDetailList[i].addPickAreas =
            //     (res.data.inquiryDetailList[i].productPickProvince
            //       ? res.data.inquiryDetailList[i].productPickProvince
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickCity
            //       ? res.data.inquiryDetailList[i].productPickCity
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickAreas
            //       ? res.data.inquiryDetailList[i].productPickAreas
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickStreet
            //       ? res.data.inquiryDetailList[i].productPickStreet
            //       : '')
            //   this.form.inquiryDetailList[i].addDispatch =
            //     (res.data.inquiryDetailList[i].productDispatchProvince
            //       ? res.data.inquiryDetailList[i].productDispatchProvince
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchCity
            //       ? res.data.inquiryDetailList[i].productDispatchCity
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchAreas
            //       ? res.data.inquiryDetailList[i].productDispatchAreas
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchStreet
            //       ? res.data.inquiryDetailList[i].productDispatchStreet
            //       : '')
            //   // if (this.form.inquiryDetailList[i].addPickAreas == 0) {
            //   //   this.form.inquiryDetailList[i].addPickAreas = ''
            //   // }
            //   // if (this.form.inquiryDetailList[i].addDispatch == 0) {
            //   //   this.form.inquiryDetailList[i].addDispatch = ''
            //   // }
            // }
          } else {
            const res = await bulkCargoCopy({ id: row.id })
            res.data.inquiryPickList.forEach((one) => {
              this.addValueOptions(one.areasId)
            })
            res.data.inquiryDispatchList.forEach((one) => {
              this.addValueOptionsTwo(one.areasId)
            })
            this.form = Object.assign({}, res.data)
            this.addPick(res.data)
            // for (let i = 0; i < res.data.inquiryDetailList.length; i++) {
            //   this.form.inquiryDetailList[i].addPickAreas =
            //     res.data.inquiryDetailList[i].productPickProvince +
            //     res.data.inquiryDetailList[i].productPickCity +
            //     res.data.inquiryDetailList[i].productPickAreas +
            //     res.data.inquiryDetailList[i].productPickStreet
            //   this.form.inquiryDetailList[i].addDispatch =
            //     res.data.inquiryDetailList[i].productDispatchProvince +
            //     res.data.inquiryDetailList[i].productDispatchCity +
            //     res.data.inquiryDetailList[i].productDispatchAreas +
            //     res.data.inquiryDetailList[i].productDispatchStreet
            //   this.form.inquiryDetailList[i].addPickAreas =
            //     (res.data.inquiryDetailList[i].productPickProvince
            //       ? res.data.inquiryDetailList[i].productPickProvince
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickCity
            //       ? res.data.inquiryDetailList[i].productPickCity
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickAreas
            //       ? res.data.inquiryDetailList[i].productPickAreas
            //       : '') +
            //     (res.data.inquiryDetailList[i].productPickStreet
            //       ? res.data.inquiryDetailList[i].productPickStreet
            //       : '')
            //   this.form.inquiryDetailList[i].addDispatch =
            //     (res.data.inquiryDetailList[i].productDispatchProvince
            //       ? res.data.inquiryDetailList[i].productDispatchProvince
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchCity
            //       ? res.data.inquiryDetailList[i].productDispatchCity
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchAreas
            //       ? res.data.inquiryDetailList[i].productDispatchAreas
            //       : '') +
            //     (res.data.inquiryDetailList[i].productDispatchStreet
            //       ? res.data.inquiryDetailList[i].productDispatchStreet
            //       : '')
            //   // if (this.form.inquiryDetailList[i].addPickAreas == 0) {
            //   //   this.form.inquiryDetailList[i].addPickAreas = ''
            //   // }
            //   // if (this.form.inquiryDetailList[i].addDispatch == 0) {
            //   //   this.form.inquiryDetailList[i].addDispatch = ''
            //   // }
            // }
          }
        }
        this.dialogFormVisible = true
      },
      addPick(data) {
        for (let i = 0; i < data.inquiryDetailList.length; i++) {
          this.form.inquiryDetailList[i].addPickAreas =
            data.inquiryDetailList[i].productPickProvince +
            data.inquiryDetailList[i].productPickCity +
            data.inquiryDetailList[i].productPickAreas +
            data.inquiryDetailList[i].productPickStreet
          this.form.inquiryDetailList[i].addDispatch =
            data.inquiryDetailList[i].productDispatchProvince +
            data.inquiryDetailList[i].productDispatchCity +
            data.inquiryDetailList[i].productDispatchAreas +
            data.inquiryDetailList[i].productDispatchStreet
          this.form.inquiryDetailList[i].addPickAreas =
            (data.inquiryDetailList[i].productPickProvince
              ? data.inquiryDetailList[i].productPickProvince
              : '') +
            (data.inquiryDetailList[i].productPickCity
              ? data.inquiryDetailList[i].productPickCity
              : '') +
            (data.inquiryDetailList[i].productPickAreas
              ? data.inquiryDetailList[i].productPickAreas
              : '') +
            (data.inquiryDetailList[i].productPickStreet
              ? data.inquiryDetailList[i].productPickStreet
              : '')
          this.form.inquiryDetailList[i].addDispatch =
            (data.inquiryDetailList[i].productDispatchProvince
              ? data.inquiryDetailList[i].productDispatchProvince
              : '') +
            (data.inquiryDetailList[i].productDispatchCity
              ? data.inquiryDetailList[i].productDispatchCity
              : '') +
            (data.inquiryDetailList[i].productDispatchAreas
              ? data.inquiryDetailList[i].productDispatchAreas
              : '') +
            (data.inquiryDetailList[i].productDispatchStreet
              ? data.inquiryDetailList[i].productDispatchStreet
              : '')
        }
      },
      weightData() {
        if (
          this.form.originalWeightUnit == 'T' &&
          this.form.originalWeight != ''
        ) {
          this.form.weight = (eval(this.form.originalWeight) * 1000).toFixed(3)
        } else if (this.form.originalWeight != '') {
          this.form.weight = eval(this.form.originalWeight).toFixed(3)
        }
        this.visible = false
      },
      inputChangeOne() {
        console.log(this.form.weight)
        // if (this.form.weight == '' || this.form.weight == undefined) {
        this.weightData()
        // } else if (this.form.originalWeight != '') {
        // this.visible = true
        // }
      },

      weightDataTwo() {
        if (
          this.form.originalVolumeUnit == 'CBM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'M' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'CM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = (
            eval(this.form.originalVolume) * 0.000001
          ).toFixed(3)
        } else if (
          this.form.originalVolumeUnit == 'MM' &&
          this.form.originalVolume != ''
        ) {
          this.form.volume = (
            eval(this.form.originalVolume) * 0.000000001
          ).toFixed(3)
          console.log(eval(this.form.originalVolume) * 0.000000001)
          console.log(this.form.volume)
        } else if (this.form.originalVolume != '') {
          this.form.volume = eval(this.form.originalVolume).toFixed(3)
        }
        this.visibleTwo = false
      },
      inputChangeTwo() {
        // if (this.form.volume == '' || this.form.volume == undefined) {
        this.weightDataTwo()
        // } else if (this.form.originalVolume != '') {
        // this.visibleTwo = true
        // }
      },
      //新增提货地址
      addInquiryPickList() {
        console.log(this.form.inquiryPickList)
        this.form.inquiryPickList.push({
          typeId: '1',
          place: '',
          typeName: '散货',
          provinceId: '',
          province: '',
          cityId: '',
          city: '',
          areas: '',
          areasId: '',
          address: '',
          street: '',
          streetId: '',
          uqKey: '',
          visible: false,
          visibleThree: false,
        })
      },
      //删除提货地址
      deleteInquiryPickList(item) {
        var index = this.form.inquiryPickList.indexOf(item)
        if (index !== -1) {
          this.form.inquiryPickList.splice(index, 1)
        }
      },
      //新增送货地址
      addInquiryDispatchList() {
        this.form.inquiryDispatchList.push({
          province: '',
          provinceId: '',
          city: '',
          cityId: '',
          areas: '',
          areasId: '',
          address: '',
          street: '',
          streetId: '',
          uqKey: '',
          visible: false,
          visibleFour: false,
        })
      },
      //删除送货地址
      deleteInquiryDispatchList(item) {
        var index = this.form.inquiryDispatchList.indexOf(item)
        if (index !== -1) {
          this.form.inquiryDispatchList.splice(index, 1)
        }
      },

      //新增报价信息
      addInquiryDetailList() {
        this.form.inquiryDetailList.push({
          typeId: '1',
          typeName: '散货',
          productCommissionerId: '', //产品专员id
          productCommissioner: '', //产品专员名称
          productQuotation: '', //产品报价
          customerQuotation: '', //客户报价
          dealTypeId: 7, //成交状态id
          dealType: '未成交', //成交状态名称
          logisticsTypeId: '', //物流方案id
          logisticsType: '', //物流方案名称
          remark: '', //备注
          visible: false,
          ageing: '', //时效
          logisticsDistance: '', //总公里数（Km）
          truckType: '', //车型
          includingTaxes: 1, //是否含税
          standardPick: '', //标准价提货
          standardTrunk: '', //标准价干线
          standardDispatch: '', //标准价送货
          standardRiskManage: '', //标准价风控
          // surcharge: '', //附加费
        })
      },
      //删除报价信息
      deleteInquiryDetailList(item) {
        var index = this.form.inquiryDetailList.indexOf(item)
        if (index !== -1) {
          this.form.inquiryDetailList.splice(index, 1)
        }
      },
      //业务员
      onSelectSalesman(id) {
        var data = this.optionList.salesmanList.find((item) => item.id == id)
        console.log(data)
        this.form.salesmanId = data.id
        this.form.salesman = data.name
      },
      //包装方式
      onSelectPackagin(id) {
        var data = this.optionList.packageList.find((item) => item.id == id)
        this.form.packagingMethodId = data.id
        this.form.packagingMethod = data.name
      },
      //提货地址
      onSelectAreasList(uqKey, index) {
        var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
        // console.log(data)
        this.form.inquiryPickList[index].provinceId = data.provinceId
        this.form.inquiryPickList[index].province = data.provinceName
        this.form.inquiryPickList[index].cityId = data.cityId
        this.form.inquiryPickList[index].city = data.cityName
        this.form.inquiryPickList[index].areas = data.areasName
        this.form.inquiryPickList[index].areasId = data.areasId
        this.form.inquiryPickList[index].uqKey = uqKey
        this.form.inquiryPickList[index].streetId = data.streetId
        this.form.inquiryPickList[index].street = data.streetName
        // this.form.inquiryPickList[index].address = data.address
      },
      //送货地址信息
      onSelectAreasListTwo(uqKey, index) {
        var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
        console.log(data)
        this.form.inquiryDispatchList[index].provinceId = data.provinceId
        this.form.inquiryDispatchList[index].province = data.provinceName
        this.form.inquiryDispatchList[index].cityId = data.cityId
        this.form.inquiryDispatchList[index].city = data.cityName
        this.form.inquiryDispatchList[index].areas = data.areasName
        this.form.inquiryDispatchList[index].areasId = data.areasId
        this.form.inquiryDispatchList[index].uqKey = uqKey
        this.form.inquiryDispatchList[index].streetId = data.streetId
        this.form.inquiryDispatchList[index].street = data.streetName
        // this.form.inquiryDispatchList[index].address = data.address
      },
      //产品专员信息
      onSelectAreasListThree(id, index) {
        var data = this.optionList.productCommissionerList.find(
          (item) => item.id == id
        )
        this.form.inquiryDetailList[index].productCommissionerId = data.id
        this.form.inquiryDetailList[index].productCommissioner = data.name
      },

      //成交状态信息
      onSelectAreasListFour(id, index) {
        if (id == 7) {
          var data = this.optionList.dealList.find((item) => item.id == id)
          this.form.inquiryDetailList[index].dealTypeId = data.id
          this.form.inquiryDetailList[index].dealType = data.name
          return
        }
        if (this.form.inquiryDetailList[index].customerQuotation) {
          var data = this.optionList.dealList.find((item) => item.id == id)
          this.form.inquiryDetailList[index].dealTypeId = data.id
          this.form.inquiryDetailList[index].dealType = data.name
        } else {
          this.$baseMessage('请先维护总销售价！', 'warning')
          this.form.inquiryDetailList[index].dealTypeId = 7
        }
      },
      //物流方案信息
      onSelectAreasListFive(id, index) {
        var data = this.optionList.distList.find((item) => item.id == id)
        this.form.inquiryDetailList[index].logisticsTypeId = data.id
        this.form.inquiryDetailList[index].logisticsType = data.name
      },
      closeEdit() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.checked = false
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.checked = false
      },

      save() {
        console.log(this.form)
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.bulkEdit == 'add' || this.bulkEdit == 'copy') {
              const res = await bulkCargoSave(this.form)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            } else if (valid && this.bulkEdit == 'update') {
              const res = await bulkCargoEdit(this.form)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            }
            this.$emit('change')
            if (this.checked) {
              this.closeEdit()
            } else {
              this.close()
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dialog {
    overflow: auto;
  }
  ::v-deep {
    .el-dialog__header {
      padding: 0px;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .el-input--small .el-input__inner {
      border-radius: 5px;
    }
    .el-autocomplete-suggestion {
      width: auto !important;
    }
  }
  .fomrDisply {
    display: flex;
  }
  .bt {
    width: 150px;
    padding: 10px;
    color: #817f7f;

    background: #409eff;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }
  .conte {
    border-bottom: 0px;
    height: 550px;
    overflow-y: auto;
    //   padding: 20px;
  }
  .el-input {
    width: 180px;
  }

  .el-textarea__inner {
    width: 100% !important;
  }
  .conte1 {
    border: 1px solid #c9c9c9;
    //   border-bottom: 1px solid #c9c9c9;
    padding: 10px 20px 5px 20px;
    .conte-bt {
      display: flex;
      font-size: 13px;
      font-weight: 600;
      line-height: 25px;
      color: #000;
      padding-bottom: 10px;
    }
    .conte-input {
      display: flex;
    }
    .an {
      font-size: 18px;
      padding-left: 15px;
      cursor: pointer;
    }
    .ri-add-box-fill:before {
      font-size: 18px;
      color: #409eff;
    }
    .ri-checkbox-indeterminate-line:before {
      font-size: 18px;
      color: #f56c6c;
      padding-left: 5px;
    }
    .conte1-disp {
      display: flex;
    }
  }
</style>
