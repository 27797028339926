<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="form.typeId" @tab-click="handleBlur">
        <el-tab-pane label="散货" name="1" />
        <el-tab-pane label="拖车" name="2" />
        <!-- <el-tab-pane label="整车" name="3" /> -->
      </el-tabs>
    </div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="70px"
          :model="form"
          @submit.native.prevent
        >
          <el-form-item label="提货地址">
            <el-input
              v-model="form.pick"
              clearable
              placeholder="请输入提货地址"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <el-form-item
            :label="
              form.typeId == '1'
                ? '送货地址'
                : form.typeId == '2'
                ? '起运港'
                : ''
            "
          >
            <el-input
              v-model="form.dispatch"
              placeholder="请输入"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <el-form-item label="成交状态">
            <el-select
              v-model="form.dealTypeId"
              clearable
              placeholder="请选择"
              style="width: 180px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.dealList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客户" label-width="45px">
            <el-input
              v-model="form.customer"
              placeholder="请输入客户"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <el-form-item v-show="!fold" label="产品专员">
            <el-select
              v-model="form.productCommissionerId"
              clearable
              filterable
              placeholder="请选择"
              style="width: 180px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-show="!fold" label="业务员">
            <el-select
              v-model="form.salesmanId"
              clearable
              filterable
              placeholder="请选择"
              style="width: 180px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.salesmanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-show="!fold" label="日期">
            <el-date-picker
              v-model="beginTime"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              style="width: 350px"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="pickerClick"
            />
            <!-- <el-input v-model="form.creatDate" placeholder="请输入标题" /> -->
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          @click="handleAdd('', 'add')"
        >
          新建
        </el-button>
        <el-button
          icon="el-icon-download"
          :loading="loadingExPort"
          size="mini"
          type="primary"
          @click="exPort"
        >
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div v-if="form.typeId == '1'">
      <bulk-cargo
        ref="tableOne"
        :form="form"
        :handle-add="handleAdd"
        @customEvent="handleCustomEvent"
      />
    </div>
    <div v-else-if="form.typeId == '2'">
      <Trailer
        ref="tableTwo"
        :form="form"
        :handle-add="handleAdd"
        @customEvent="handleCustomEvent"
      />
    </div>
    <div v-else>
      <WholeVehicle
        ref="tableThree"
        :form="form"
        :handle-add="handleAdd"
        @customEvent="handleCustomEvent"
      />
    </div>
    <BulkCargoEdit
      ref="edit1"
      :option-list="optionList"
      @change="queryList($event)"
    />
    <TrailerEdit
      ref="edit2"
      :option-list="optionList"
      @change="queryList($event)"
    />
    <WholeVehicleEdit
      ref="edit3"
      :option-list="optionList"
      @change="queryList($event)"
    />
  </div>
</template>
<script>
  import { costomerGetValidList } from '@/api/businessReport'
import {
  appgGetPortList,
  getValidList,
  portGetValidList,
  productCommissionerGetValidList,
  salesmanGetValidList,
  streetAndAreasGetValidList,
} from '@/api/inquiryManagement'
import axios from 'axios'
import BulkCargo from './components/BulkCargo'
import BulkCargoEdit from './components/BulkCargoEdit'
import Trailer from './components/Trailer'
import TrailerEdit from './components/TrailerEdit'
import WholeVehicle from './components/WholeVehicle.vue'
import WholeVehicleEdit from './components/WholeVehicleEdit.vue'
  export default {
    name: 'InquiryManagement',
    components: {
      BulkCargo,
      Trailer,
      BulkCargoEdit,
      TrailerEdit,
      WholeVehicle,
      WholeVehicleEdit,
    },
    data() {
      return {
        loadingExPort: false,
        fold: true,
        optionList: {
          areasList: [], //省市区
          dealList: [], //成交状态
          packageList: [], //包装方式
          salesmanList: [], //业务员
          productCommissionerList: [], //产品专员
          distList: [], //散货物流方案
          carList: [], //拖车物流方案
          cabinetList: [], //柜型
          portList: [], //港口
          costomerGetValidList: [], //客户
          taxList: [
            {
              id: 1,
              name: '含税',
            },
            {
              id: 0,
              name: '不含税',
            },
          ], //是否含税
        },
        beginTime: [],
        pickerOptions: {},
        idList: [],
        form: {
          typeId: '1',
          pick: '',
          dispatch: '',
          dealTypeId: '',
          customer: '',
          productCommissionerId: '',
          salesmanId: '',
          startDate: '',
          endDate: '',
        },
      }
    },
    // mounted() {
    //   this.selectList()
    //   this.handleBlur()
    // },
    activated() {
      this.selectList()
      this.handleBlur()
    },
    methods: {
      async selectList() {
        Promise.all(
          [
            getValidList({ typeId: 3 }),
            streetAndAreasGetValidList({}),
            salesmanGetValidList({}),
            productCommissionerGetValidList({}),
            getValidList({ typeId: 5 }),
            getValidList({ typeId: 4 }),
            getValidList({ typeId: 2 }),
            getValidList({ typeId: 6 }),
            portGetValidList({}),
            costomerGetValidList({}),
            appgGetPortList({}),
          ].map((v) => v.catch((e) => console.log(e)))
        ).then((res) => {
          this.optionList.dealList = res[0].data
          this.optionList.areasList = res[1].data
          this.optionList.salesmanList = res[2].data
          this.optionList.productCommissionerList = res[3].data
          this.optionList.distList = res[4].data
          this.optionList.carList = res[5].data
          this.optionList.packageList = res[6].data
          this.optionList.cabinetList = res[7].data
          this.optionList.portList = res[8].data
          this.optionList.costomerGetValidList = res[9].data
          console.log(res[10].data)
        })

        // const res = await getValidList({ typeId: 3 })
        // this.optionList.dealList = res.data
        // const rew = await streetAndAreasGetValidList({})
        // this.optionList.areasList = rew.data
        // const req = await salesmanGetValidList({})
        // this.optionList.salesmanList = req.data
        // const red = await productCommissionerGetValidList({})
        // this.optionList.productCommissionerList = red.data
        // const ref = await getValidList({ typeId: 5 })
        // this.optionList.distList = ref.data
        // const rel = await getValidList({ typeId: 4 })
        // this.optionList.carList = rel.data
        // const reg = await getValidList({ typeId: 2 })
        // this.optionList.packageList = reg.data
        // const reo = await getValidList({ typeId: 6 })
        // this.optionList.cabinetList = reo.data
        // const rep = await portGetValidList({})
        // this.optionList.portList = rep.data
        // const reb = await costomerGetValidList({})
        // this.optionList.costomerGetValidList = reb.data
        // this.$refs['edit1'].areasFilterOne()
        // this.$refs['edit2'].areasFilterOne()
        // this.$refs['edit1'].areasFilterTwo()
      },
      pickerClick() {
        this.form.startDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },

      queryList() {
        this.$nextTick(() => {
          switch (this.form.typeId) {
            case '1':
              this.$refs['tableOne'].handleQuery()
              break
            case '2':
              this.$refs['tableTwo'].handleQuery()
              break
            case '3':
              this.$refs['tableThree'].handleQuery()
              break
            default:
              break
          }
          // if (this.form.typeId == '1') {
          //   this.$refs['tableOne'].handleQuery()
          // } else {
          //   this.$refs['tableTwo'].handleQuery()
          // }
        })
      },
      handleBlur() {
        this.queryList()
      },
      handleFold() {
        this.fold = !this.fold
      },
      handleAdd(row = '', type) {
        switch (this.form.typeId) {
          case '1':
            this.$refs['edit1'].showEdit(row, type)
            break
          case '2':
            this.$refs['edit2'].showEdit(row, type)
            break
          case '3':
            this.$refs['edit3'].showEdit(row, type)
            break
          default:
            break
        }
        // if (this.form.typeId == '1') {
        //   this.$refs['edit1'].showEdit(row, type)
        // } else if (this.form.typeId == '2') {
        //   this.$refs['edit2'].showEdit(row, type)
        // }
      },
      handleCustomEvent(val) {
        this.idList = val
      },
      exPort() {
        console.log(this.form)
        console.log(this.form.typeId)
        this.loadingExPort = true
        let data = this.form
        this.$set(data, 'idList', this.idList)
        console.log(data)
        axios({
          url: `/api/inquiryInfo/export`,
          data,
          method: 'post',
          responseType: 'blob',
          headers: {
            token: localStorage.getItem('token'),
          },
        })
          .then((response) => {
            const content = response.data
            const blob = new Blob([content])
            // const fileName = decodeURIComponent(
            //   response.headers['content-disposition'].split('=')[1] //文件名
            // )
            const fileName =
              response.headers['content-disposition'].split('=')[1] //文件名
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = decodeURIComponent(fileName)
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            // eslint-disable-next-line handle-callback-err
            this.loadingExPort = false
          })
          .catch((error) => {
            this.loadingExPort = false
            this.$baseMessage('导出失败！', 'error', 'vab-hey-message-success')
            console.log(error)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-input {
    width: 180px !important;
  }
  .title {
    text-align: center;
    color: #000;
    font-weight: 600;
    font-size: 20px;
  }
  .bt {
    float: right;
    margin: 10px 0px;
  }

  ::v-deep {
    .el-tooltip__popper {
      max-width: 180px;
      overflow: hidden;
    }
  }
</style>
