<template>
  <el-dialog
    class="dialog"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="1000px"
    @close="close"
  >
    <div class="bt">拖车</div>
    <div class="conte">
      <el-form
        ref="form"
        label-width="80px"
        :model="form"
        :rules="rules"
        :inline="true"
      >
        <div class="conte1">
          <div class="conte-bt">询价信息</div>
          <el-form-item label="客户" prop="customer">
            <el-autocomplete
              class="inline-input"
              v-model="form.customer"
              :fetch-suggestions="querySearchKe"
              placeholder="请输入内容"
              clearable
              @select="handleSelectKe"
            >
              <template slot-scope="{ item }">
                <div>
                  {{ item.name }}
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="业务员" prop="salesmanId">
            <el-select
              v-model="form.salesmanId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 150px"
              @change="onSelectSalesman"
            >
              <el-option
                v-for="item in optionList.salesmanList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="inquiryPickList">
            <div
              v-for="(domain, index) in form.inquiryPickList"
              :key="index"
              class="conte-input"
            >
              <div>
                <div style="margin-left: 5px; display: inline">
                提货地址
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="“东莞市/中山市/东沙群岛/嘉峪关市/晋城市/济源市，这类特殊地址只能解析为市辖区。若需更详细的地址，请手动选择”"
                  placement="top"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
                <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="domain.place"
                  :fetch-suggestions="querySearch"
                  placeholder="输入待解析的地址"
                  @select="handleSelect($event, index)"
                  @change="hendQuery(domain, index)"
                  style="width: 300px"
                  :popper-append-to-body="false"
                >
                  <template slot-scope="{ item }">
                    <div style="color: #409eff">
                      {{ item.name }}
                    </div>
                    <div class="name" style="font-size: 10px">
                      {{ item.cityName
                      }}{{ item.areasName }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                        item.address
                      }}
                    </div>
                    <span class="addr"></span>
                    <span class="addr"></span>
                  </template>
                </el-autocomplete>
                </div>
                <el-select
                  v-model="domain.uqKey"
                  filterable
                  placeholder="请选择"
                  style="margin: 0px 10px; width: 180px"
                  @change="onSelectAreasList(domain.uqKey, index)"
                  :filter-method="areasFilterOne"
                >
                  <el-option
                    v-for="item in areasListOne"
                    :key="item.uqKey"
                    :label="item.fullName"
                    :value="item.uqKey"
                  ></el-option>
                </el-select>
                <el-input
                  placeholder="详细地址"
                  v-model="domain.address"
                  clearable
                  maxlength="100"
                  style="width: 250px"
                ></el-input>
                <!-- <el-popover
                  placement="bottom"
                  width="160"
                  v-model="domain.visibleThree"
                >
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visibleThree = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="placeClickOne(domain, index)"
                    >
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
              </div>
              <div class="an">
                <span>
                  <vab-icon icon="add-box-fill" @click="addInquiryPickList" />
                </span>
                <span v-if="index != 0">
                  <el-popover
                    placement="right"
                    width="160"
                    v-model="domain.visible"
                  >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="domain.visible = false"
                      >
                        取消
                      </el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        @click="deleteInquiryPickList(domain)"
                      >
                        确定
                      </el-button>
                    </div>
                    <vab-icon
                      slot="reference"
                      icon="checkbox-indeterminate-line"
                    />
                  </el-popover>
                </span>
              </div>
            </div>
          </el-form-item>
          <div
            v-for="(domain, index) in form.inquiryDispatchPortList"
            :key="domain.id"
            class="conte-input"
          >
            <el-form-item
              label="送货地址"
              :prop="'inquiryDispatchPortList.' + index + '.portId'"
              :rules="[
                {
                  required: true,
                  trigger: 'change',
                  message: '请选择送货地址',
                },
              ]"
            >
              <!-- <div> -->
              <el-select
                v-model="domain.portId"
                filterable
                placeholder="请选择"
                style="margin-right: 10px"
                @change="onSelectpPort(domain.portId)"
              >
                <el-option
                  v-for="item in optionList.portList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <!-- <el-input
                  placeholder="详细地址"
                  v-model="domain.address"
                ></el-input> -->
              <!-- </div> -->
              <!-- <div class="an">
                <span>
                  <vab-icon
                    icon="add-box-fill"
                    @click="addInquiryDispatchList"
                  />
                </span>
                <span v-if="index != 0">
                  <vab-icon
                    icon="checkbox-indeterminate-line"
                    @click="deleteInquiryDispatchList(domain)"
                  />
                </span> -->
              <!-- </div> -->
            </el-form-item>
          </div>
          <div
            v-for="(domain, index) in form.inquiryGoodsList"
            :key="index"
            class="conte-input"
          >
            <el-form-item
              label="柜型"
              :prop="'inquiryGoodsList.' + index + '.cabinetTypeId'"
              :rules="[
                { required: true, trigger: 'change', message: '请选择柜型' },
              ]"
            >
              <el-select
                v-model="domain.cabinetTypeId"
                filterable
                placeholder="请选择"
                style="margin-right: 10px; width: 130px"
                @change="onSelectAreasListCabinet(domain.cabinetTypeId, index)"
              >
                <el-option
                  v-for="item in optionList.cabinetList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="柜量"
              :prop="'inquiryGoodsList.' + index + '.cabinetNum'"
              :rules="[
                { required: true, trigger: 'blur', message: '请输入柜量' },
              ]"
            >
              <el-input-number
                v-model="domain.cabinetNum"
                :min="1"
                :max="1000"
                label="描述文字"
              ></el-input-number>
              <!-- <el-input
                placeholder="柜量"
                v-model.trim="domain.cabinetNum"
                oninput="value=value.replace(/[^\d]/g, '')"
                @blur="domain.cabinetNum = $event.target.value"
                style="width: 120px"
              /> -->
            </el-form-item>
            <div class="an" style="padding-top: 6px">
              <span>
                <vab-icon icon="add-box-fill" @click="addInquiryGoodsList" />
              </span>
              <span v-if="index != 0">
                <el-popover
                  placement="right"
                  width="160"
                  v-model="domain.visible"
                >
                  <p>确定删除吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visible = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="deleteInquiryGoodsList(domain)"
                    >
                      确定
                    </el-button>
                  </div>
                  <vab-icon
                    slot="reference"
                    icon="checkbox-indeterminate-line"
                  />
                </el-popover>
              </span>
            </div>
          </div>
          <el-form-item label="重量" prop="weight">
            <div class="fomrDisply">
              <div>
                <el-input
                  v-model.trim="form.weight"
                  clearable
                  style="width: 200px"
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
                  @blur="form.weight = $event.target.value"
                  slot="reference"
                >
                  <template slot="append">&nbsp;T&nbsp;&nbsp;</template>
                </el-input>
                <!-- <el-popover placement="bottom" width="160" v-model="visible">
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">
                      取消
                    </el-button>
                    <el-button type="primary" size="mini" @click="weightData()">
                      确定
                    </el-button>
                  </div>
                </el-popover> -->
              </div>
              <div style="padding-left: 10px">
                <el-input
                  clearable
                  placeholder="请输入数字和运算符，乘号只能用*"
                  class="input-with-select"
                  v-model.trim="form.originalWeight"
                  oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
                  @blur="form.originalWeight = $event.target.value"
                  @keyup.enter.native="inputChangeOne"
                  style="width: 400px"
                ></el-input>
                <el-select
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 80px"
                  v-model="form.originalWeightUnit"
                  @change="inputChangeOne"
                >
                  <el-option label="KG" value="KG"></el-option>
                  <el-option label="T" value="T"></el-option>
                </el-select>
              </div>
            </div>
            <!-- <el-input
              placeholder="重量"
              v-model.trim="form.weight"
              clearable
              maxlength="100"
              style="width: 120px"
            /> -->
          </el-form-item>
          <!-- <el-form-item label="体积" prop="volume">
            <div class="fomrDisply">
              <div>
                <el-input
                  v-model.trim="form.volume"
                  clearable
                  style="width: 200px"
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/,'$1')"
                  @blur="form.volume = $event.target.value"
                  slot="reference"
                >
                  <template slot="append">CBM</template>
                </el-input>
                <el-popover placement="bottom" width="160" v-model="visibleTwo">
                  <p>警告：</p>
                  <p>新解析的值将覆盖已存在的值</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="visibleTwo = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="weightDataTwo()"
                    >
                      确定
                    </el-button>
                  </div>
                </el-popover>
              </div>
              <div style="padding-left: 10px">
                <el-input
                  clearable
                  placeholder="请输入数字和运算符，乘号只能用*"
                  class="input-with-select"
                  v-model.trim="form.originalVolume"
                  oninput="value=value.replace(/[^0-9+().*%/-]/g,'')"
                  @blur="form.originalVolume = $event.target.value"
                  @keyup.enter.native="inputChangeTwo"
                  style="width: 400px"
                ></el-input>
                <el-select
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 80px"
                  v-model="form.originalVolumeUnit"
                  @change="inputChangeTwo"
                >
                  <el-option label="CBM" value="CBM"></el-option>
                  <el-option label="M" value="M"></el-option>
                  <el-option label="CM" value="CM"></el-option>
                  <el-option label="MM" value="MM"></el-option>
                </el-select>
              </div>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="包装方式" prop="packagingMethodId">
            <el-select
              v-model="form.packagingMethodId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px"
              @change="onSelectPackagin"
            >
              <el-option
                v-for="item in optionList.packageList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :rows="3"
              maxlength="200"
              placeholder="请输入内容"
              v-model="form.remark"
              style="width: 650px"
            ></el-input>
          </el-form-item>
        </div>
        <div
          class="conte1"
          v-for="(domain, index) in form.inquiryDetailList"
          :key="index"
        >
          <div class="conte1-disp">
            <div class="conte-bt">报价信息</div>
            <div class="an">
              <span>
                <vab-icon icon="add-box-fill" @click="addInquiryDetailList" />
              </span>
              <span v-if="index != 0">
                <el-popover
                  placement="right"
                  width="160"
                  v-model="domain.visible"
                >
                  <p>确定删除吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="domain.visible = false"
                    >
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      size="mini"
                      @click="deleteInquiryDetailList(domain)"
                    >
                      确定
                    </el-button>
                  </div>
                  <vab-icon
                    slot="reference"
                    icon="checkbox-indeterminate-line"
                  />
                </el-popover>
              </span>
            </div>
          </div>
          <div>
            <el-form-item label="提货地址:" prop="inquiryDetailList">
              <el-input
                v-model.trim="domain.addPickAreas"
                clearable
                disabled
                style="width: 300px"
              />
            </el-form-item>
          </div>
          <el-form-item label="产品专员" prop="inquiryDetailList">
            <el-select
              v-model="domain.productCommissionerId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="
                onSelectAreasListThree(domain.productCommissionerId, index)
              "
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- <el-input v-model.trim="domain.title" style="width: 130px" /> -->
          </el-form-item>
          <el-form-item label="" prop="inquiryDetailList">
            总标准价
            <el-tooltip
              effect="dark"
              content="产品报价"
              placement="top"
              style="padding-right: 6px"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-input
              placeholder="总标准价"
              v-model.trim="domain.productQuotation"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.productQuotation = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="" prop="inquiryDetailList">
            总销售价
            <el-tooltip
              effect="dark"
              content="客户报价"
              placement="top"
              style="padding-right: 6px"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
            <el-input
              placeholder="总销售价"
              v-model.trim="domain.customerQuotation"
              clearable
              maxlength="12"
              :disabled="domain.dealTypeId == 6"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.customerQuotation = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="成交状态" prop="inquiryDetailList">
            <el-select
              v-model="domain.dealTypeId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="onSelectAreasListFour(domain.dealTypeId, index)"
            >
              <el-option
                v-for="item in optionList.dealList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流方案" prop="inquiryDetailList">
            <el-select
              v-model="domain.logisticsTypeId"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
              @change="onSelectAreasListFive(domain.logisticsTypeId, index)"
            >
              <el-option
                v-for="item in optionList.carList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时效" prop="inquiryDetailList">
            <el-input
              placeholder="时效"
              v-model.trim="domain.ageing"
              maxlength="12"
              clearable
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="是否含税" prop="inquiryDetailList">
            <el-select
              v-model="domain.includingTaxes"
              filterable
              placeholder="请选择"
              style="margin-right: 10px; width: 130px"
            >
              <el-option
                v-for="item in optionList.taxList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <h5>标准价明细</h5>
          <el-form-item label="20GP" prop="inquiryDetailList">
            <el-input
              placeholder="20GP"
              v-model.trim="domain.standard_20gp"
              maxlength="12"
              clearable
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.standard_20gp = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="40GP" prop="inquiryDetailList">
            <el-input
              placeholder="40GP"
              v-model.trim="domain.standard_40gp"
              maxlength="12"
              clearable
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.standard_40gp = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="40HQ" prop="inquiryDetailList">
            <el-input
              placeholder="40HQ"
              v-model.trim="domain.standard_40hq"
              maxlength="12"
              clearable
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.standard_40hq = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item label="45HQ" prop="inquiryDetailList">
            <el-input
              placeholder="45HQ"
              v-model.trim="domain.standard_45hq"
              maxlength="12"
              clearable
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              @blur="domain.standard_45hq = $event.target.value"
              style="width: 130px"
            />
          </el-form-item>
          <el-form-item :inline="false" label="附加费" prop="inquiryDetailList">
            <el-input
              type="textarea"
              :rows="3"
              maxlength="200"
              placeholder="请输入内容"
              v-model="domain.surcharge"
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <el-form-item :inline="false" label="备注" prop="inquiryDetailList">
            <el-input
              type="textarea"
              :rows="3"
              maxlength="200"
              placeholder="请输入内容"
              v-model="domain.remark"
              style="width: 650px"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div style="float: left">
        <el-checkbox v-model="checked">继续创建下一条</el-checkbox>
      </div>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  bulkCargoCarSave,
  bulkCargoCarGetById,
  bulkCargoCarEdit,
  bulkCargoCarCopy,
  areasPlaceSuggestion,
} from '@/api/inquiryManagement'
export default {
  props: {
    optionList: Object,
  },
  name: 'TableEdit',
  data() {
    return {
      visible: false,
      visibleTwo: false,

      placeObject: {}, //解析地址
      placeList: [],
      areasListOne: [], //提货地址
      cbFun: null,
      form: {
        typeId: '2',
        typeName: '拖车',
        customer: '', //客户
        salesmanId: '', //业务员id
        salesman: '', //业务员
        weight: '', //重量
        originalWeight: '', //重量公式
        originalWeightUnit: 'T', //重量单位
        originalVolume: '', //体积公式
        originalVolumeUnit: 'CBM', //体积单位
        volume: '', //体积
        // packagingMethodId: '', //包装方式id
        // packagingMethod: '', //包装方式名称
        remark: '', //备注
        inquiryPickList: [
          {
            typeId: '2',
            typeName: '拖车',
            place: '',
            provinceId: '',
            province: '',
            cityId: '',
            city: '',
            areas: '',
            areasId: '',
            address: '',
            street: '',
            streetId: '',
            uqKey: '',
            visible: false,
            visibleThree: false,
          },
        ],
        inquiryDispatchPortList: [
          {
            portId: '',
            portName: '',
          },
        ],
        //柜型柜量
        inquiryGoodsList: [
          {
            cabinetTypeId: '',
            cabinetType: '',
            cabinetNum: '',
            visible: false,
          },
        ],
        // inquiryDispatchList: [
        //   {
        //     province: '',
        //     provinceId: '',
        //     city: '',
        //     cityId: '',
        //     areas: '',
        //     areasId: '',
        //   },
        // ],
        inquiryDetailList: [
          {
            typeId: '2',
            typeName: '拖车',
            productCommissionerId: '', //产品专员id
            productCommissioner: '', //产品专员名称
            productQuotation: '', //产品报价
            customerQuotation: '', //客户报价
            dealTypeId: 7, //成交状态id
            dealType: '未成交', //成交状态名称
            logisticsTypeId: 8, //物流方案id
            logisticsType: '港口拖车', //物流方案名称
            remark: '', //备注
            visible: false,
            ageing: '', //时效
            includingTaxes: 1, //是否含税
            standard_20gp: '', //标准价20GP
            standard_40gp: '', //标准价40GP
            standard_40hq: '', //标准价40HQ
            standard_45hq: '', //标准价45HQ
            surcharge: '', //附加费
            productPickAreas: '',
            productPickStreet: '',
            productPickKey: '',
            addPickAreas: '',
          },
        ],
      },
      checked: false,
      optionsOne: [],
      rules: {
        // customer: [
        //   { required: true, trigger: 'blur', message: '请输入客户' },
        //   { required: true, trigger: 'change', message: '请输入客户' },
        // ],
        // salesmanId: [
        //   { required: true, trigger: 'change', message: '请选择业务员' },
        // ],
        // weight: [{ required: true, trigger: 'blur', message: '请输入重量' }],
        // volume: [{ required: true, trigger: 'blur', message: '请输入体积' }],
      },
      title: '',
      dialogFormVisible: false,
      bulkEdit: '',
    }
  },
  created() {},
  methods: {
    querySearchKe(queryString, cb) {
      var restaurants = this.optionList.costomerGetValidList
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelectKe(val) {
      this.form.customer = val.name
    },
    async showEdit(row, type) {
      this.areasFilterOne()
      this.bulkEdit = type
      if (row) {
        if (type == 'update') {
          const res = await bulkCargoCarGetById({ id: row.id })
          res.data.inquiryPickList.forEach((one) => {
            this.addValueOptions(one.areasId)
          })
          this.form = Object.assign({}, res.data)
          this.addPick(res.data)
        } else {
          const res = await bulkCargoCarCopy({ id: row.id })
          res.data.inquiryPickList.forEach((one) => {
            this.addValueOptions(one.areasId)
          })
          this.form = Object.assign({}, res.data)
          this.addPick(res.data)
        }
      }
      this.dialogFormVisible = true
    },
    addPick(data) {
      for (let i = 0; i < data.inquiryDetailList.length; i++) {
        this.form.inquiryDetailList[i].addPickAreas =
          data.inquiryDetailList[i].productPickProvince +
          data.inquiryDetailList[i].productPickCity +
          data.inquiryDetailList[i].productPickAreas +
          data.inquiryDetailList[i].productPickStreet
        this.form.inquiryDetailList[i].addPickAreas =
          (data.inquiryDetailList[i].productPickProvince
            ? data.inquiryDetailList[i].productPickProvince
            : '') +
          (data.inquiryDetailList[i].productPickCity
            ? data.inquiryDetailList[i].productPickCity
            : '') +
          (data.inquiryDetailList[i].productPickAreas
            ? data.inquiryDetailList[i].productPickAreas
            : '') +
          (data.inquiryDetailList[i].productPickStreet
            ? data.inquiryDetailList[i].productPickStreet
            : '')
      }
    },

    /****重量体积 */
    weightData() {
      if (
        this.form.originalWeightUnit == 'T' &&
        this.form.originalWeight != ''
      ) {
        this.form.weight = eval(this.form.originalWeight).toFixed(3)
      } else if (this.form.originalWeight != '') {
        this.form.weight = (eval(this.form.originalWeight) / 1000).toFixed(3)
      }
      this.visible = false
    },
    inputChangeOne() {
      console.log(this.form.weight)
      // if (this.form.weight == '' || this.form.weight == undefined) {
        this.weightData()
      // } else if (this.form.originalWeight != '') {
        // this.visible = true
      // }
    },

    weightDataTwo() {
      if (
        this.form.originalVolumeUnit == 'CBM' &&
        this.form.originalVolume != ''
      ) {
        this.form.volume = eval(this.form.originalVolume).toFixed(3)
      } else if (
        this.form.originalVolumeUnit == 'M' &&
        this.form.originalVolume != ''
      ) {
        this.form.volume = eval(this.form.originalVolume).toFixed(3)
      } else if (
        this.form.originalVolumeUnit == 'CM' &&
        this.form.originalVolume != ''
      ) {
        this.form.volume = (eval(this.form.originalVolume) * 0.000001).toFixed(
          3
        )
      } else if (
        this.form.originalVolumeUnit == 'MM' &&
        this.form.originalVolume != ''
      ) {
        this.form.volume = (
          eval(this.form.originalVolume) * 0.000000001
        ).toFixed(3)
        console.log(eval(this.form.originalVolume) * 0.000000001)
        console.log(this.form.volume)
      } else if (this.form.originalVolume != '') {
        this.form.volume = eval(this.form.originalVolume).toFixed(3)
      }
      this.visibleTwo = false
    },
    inputChangeTwo() {
      if (this.form.volume == '' || this.form.volume == undefined) {
        this.weightDataTwo()
      } else if (this.form.originalVolume != '') {
        this.visibleTwo = true
      }
    },
    /****提货地址***/
    areasFilterOne(query = '') {
      let arr = this.optionList.areasList.filter((item) => {
        return item.fullName.includes(query) || item.fullName.includes(query)
      })
      if (arr.length > 150) {
        this.areasListOne = arr.slice(0, 150)
      } else {
        this.areasListOne = arr
      }
    },
    addValueOptions(areasId) {
      const targetList = this.optionList.areasList.filter((item) => {
        return item.areasId === areasId
      })
      this.areasListOne = targetList
      // 添加到下拉列表中
      const target = this.optionList.areasList.find((item) => {
        return item.areasId === areasId
      })
      if (target) {
        // 将当前条与小option比对，没有则加入
        if (
          this.areasListOne.every((item) => item.areasId !== target.areasId)
        ) {
          this.areasListOne.unshift(target)
        }
      }
    },
    //提货地址解析
    async querySearch(queryString, cb) {
      this.cbFun = cb
      // const res = await areasPlaceSuggestion({ place: queryString })
      // this.placeList = res.data
      var restaurants = this.placeList
      cb(restaurants)
    },

    placeClickOne(domain, index) {
      this.placeAnalysis(this.placeObject, index)
      this.form.inquiryPickList[index].visibleThree = false
    },

    handleSelect(list, index) {
      this.$set(list, 'place', this.placeObject.place)
      this.placeObject = list
      // if (this.form.inquiryPickList[index].uqKey == '') {
        this.placeAnalysis(list, index)
      // } else {
        // this.form.inquiryPickList[index].visibleThree = true
      // }
    },
    placeAnalysis(list, index) {
      var data = this.optionList.areasList.find(
        (item) => item.uqKey == list.uqKey
      )
      this.addValueOptions(list.areasId)
      // this.areasFilterOne(list.areasName)
      this.form.inquiryPickList[index].provinceId = data.provinceId
      this.form.inquiryPickList[index].province = data.provinceName
      this.form.inquiryPickList[index].cityId = data.cityId
      this.form.inquiryPickList[index].city = data.cityName
      this.form.inquiryPickList[index].areas = data.areasName
      this.form.inquiryPickList[index].areasId = data.areasId
      this.form.inquiryPickList[index].uqKey = data.uqKey
      this.form.inquiryPickList[index].streetId = data.streetId
      this.form.inquiryPickList[index].street = data.streetName
      this.form.inquiryPickList[index].place = this.placeObject.place
      this.form.inquiryPickList[index].address =
        list.address + '(' + list.name + ')'
    },
    async hendQuery(domain, index) {
      this.placeObject.place = domain.place
      const res = await areasPlaceSuggestion({ place: domain.place })
      this.placeList = res.data
      this.querySearch(domain, this.cbFun)
    },

    //新增提货地址
    addInquiryPickList() {
      console.log(this.form.inquiryPickList)
      this.form.inquiryPickList.push({
        typeId: '2',
        typeName: '拖车',
        place: '',
        provinceId: '',
        province: '',
        cityId: '',
        city: '',
        areas: '',
        areasId: '',
        address: '',
        street: '',
        streetId: '',
        uqKey: '',
        visible: false,
        visibleThree: false,
      })
    },
    //删除提货地址
    deleteInquiryPickList(item) {
      var index = this.form.inquiryPickList.indexOf(item)
      if (index !== -1) {
        this.form.inquiryPickList.splice(index, 1)
      }
    },
    // //新增送货地址
    // addInquiryDispatchList() {
    //   this.form.inquiryDispatchList.push({
    //     province: '',
    //     provinceId: '',
    //     city: '',
    //     cityId: '',
    //     areas: '',
    //     areasId: '',
    //   })
    // },
    // //删除送货地址
    // deleteInquiryDispatchList(item) {
    //   var index = this.form.inquiryDispatchList.indexOf(item)
    //   if (index !== -1) {
    //     this.form.inquiryDispatchList.splice(index, 1)
    //   }
    // },

    //新增报价信息
    addInquiryDetailList() {
      this.form.inquiryDetailList.push({
        typeId: '2',
        typeName: '拖车',
        productCommissionerId: '', //产品专员id
        productCommissioner: '', //产品专员名称
        productQuotation: '', //产品报价
        customerQuotation: '', //客户报价
        dealTypeId: 7, //成交状态id
        dealType: '未成交', //成交状态名称
        logisticsTypeId: 8, //物流方案id
        logisticsType: '港口拖车', //物流方案名称
        remark: '', //备注
        visible: false,
      })
    },
    //删除报价信息
    deleteInquiryDetailList(item) {
      var index = this.form.inquiryDetailList.indexOf(item)
      if (index !== -1) {
        this.form.inquiryDetailList.splice(index, 1)
      }
    },
    //新增柜型柜量
    addInquiryGoodsList() {
      this.form.inquiryGoodsList.push({
        cabinetTypeId: '',
        cabinetType: '',
        cabinetNum: '',
        visible: false,
      })
    },
    //删除柜型柜量
    deleteInquiryGoodsList(item) {
      var index = this.form.inquiryGoodsList.indexOf(item)
      if (index !== -1) {
        this.form.inquiryGoodsList.splice(index, 1)
      }
    },
    //业务员
    onSelectSalesman(id) {
      var data = this.optionList.salesmanList.find((item) => item.id == id)
      this.form.salesmanId = data.id
      this.form.salesman = data.name
    },
    //包装方式
    // onSelectPackagin(id) {
    //   var data = this.optionList.packageList.find((item) => item.id == id)
    //   this.form.packagingMethodId = data.id
    //   this.form.packagingMethod = data.name
    // },
    //送货地址：港口
    onSelectpPort(id) {
      var data = this.optionList.portList.find((item) => item.id == id)
      this.$set(this.form.inquiryDispatchPortList[0], 'portId', data.id)
      this.$set(this.form.inquiryDispatchPortList[0], 'portName', data.name)
      console.log(this.form.inquiryDispatchPortList)
      // this.form.inquiryDispatchPortList = [
      //   {
      //     portId: data.id,
      //     portName: data.name,
      //   },
      // ]
    },
    //柜型
    onSelectAreasListCabinet(id, index) {
      var data = this.optionList.cabinetList.find((item) => item.id == id)
      console.log(data)
      this.form.inquiryGoodsList[index].cabinetTypeId = data.id
      this.form.inquiryGoodsList[index].cabinetType = data.name
      console.log(this.form.inquiryGoodsList)
    },
    //提货地址
    onSelectAreasList(uqKey, index) {
      var data = this.optionList.areasList.find((item) => item.uqKey == uqKey)
      // console.log(data)
      this.form.inquiryPickList[index].provinceId = data.provinceId
      this.form.inquiryPickList[index].province = data.provinceName
      this.form.inquiryPickList[index].cityId = data.cityId
      this.form.inquiryPickList[index].city = data.cityName
      this.form.inquiryPickList[index].areas = data.areasName
      this.form.inquiryPickList[index].areasId = data.areasId
      this.form.inquiryPickList[index].uqKey = uqKey
      this.form.inquiryPickList[index].streetId = data.streetId
      this.form.inquiryPickList[index].street = data.streetName
    },
    //送货地址信息
    // onSelectAreasListTwo(id, index) {
    //   var data = this.optionList.areasList.find((item) => item.id == id)
    //   this.form.inquiryDispatchList[index].provinceId = data.provinceId
    //   this.form.inquiryDispatchList[index].province = data.provinceName
    //   this.form.inquiryDispatchList[index].cityId = data.cityId
    //   this.form.inquiryDispatchList[index].city = data.cityName
    //   this.form.inquiryDispatchList[index].areas = data.areasName
    //   this.form.inquiryDispatchList[index].areasId = data.areasId
    // },
    //产品专员信息
    onSelectAreasListThree(id, index) {
      var data = this.optionList.productCommissionerList.find(
        (item) => item.id == id
      )
      this.form.inquiryDetailList[index].productCommissionerId = data.id
      this.form.inquiryDetailList[index].productCommissioner = data.name
    },

    //成交状态信息
    onSelectAreasListFour(id, index) {
      if (id == 7) {
        var data = this.optionList.dealList.find((item) => item.id == id)
        this.form.inquiryDetailList[index].dealTypeId = data.id
        this.form.inquiryDetailList[index].dealType = data.name
        return
      }
      if (this.form.inquiryDetailList[index].customerQuotation) {
        var data = this.optionList.dealList.find((item) => item.id == id)
        this.form.inquiryDetailList[index].dealTypeId = data.id
        this.form.inquiryDetailList[index].dealType = data.name
      } else {
        this.$baseMessage('请先维护总销售价！', 'warning')
        this.form.inquiryDetailList[index].dealTypeId = 7
      }
    },
    //物流方案信息
    onSelectAreasListFive(id, index) {
      var data = this.optionList.carList.find((item) => item.id == id)
      this.form.inquiryDetailList[index].logisticsTypeId = data.id
      this.form.inquiryDetailList[index].logisticsType = data.name
    },
    closeEdit() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.checked = false
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
      this.checked = false
    },

    save() {
      console.log(this.form)
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.bulkEdit == 'add' || this.bulkEdit == 'copy') {
            const res = await bulkCargoCarSave(this.form)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          } else if (valid && this.bulkEdit == 'update') {
            const res = await bulkCargoCarEdit(this.form)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          }
          this.$emit('change')
          if (this.checked) {
            this.closeEdit()
          } else {
            this.close()
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog {
  overflow: auto;
}
::v-deep {
  .el-dialog__header {
    padding: 0px;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-input--small .el-input__inner {
    border-radius: 5px;
  }
  // .el-form--inline .el-form-item {
  //   margin-right: 0px;
  // }
  .el-autocomplete-suggestion {
    width: auto !important;
  }
}
.fomrDisply {
  display: flex;
}
.bt {
  width: 150px;
  padding: 10px;
  background: #409eff;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #fff;
}
.conte {
  border-bottom: 0px;
  height: 550px;
  overflow-y: auto;
  //   padding: 20px;
}
.el-input {
  width: 180px;
}

.el-textarea__inner {
  width: 100% !important;
}
.conte1 {
  border: 1px solid #c9c9c9;
  //   border-bottom: 1px solid #c9c9c9;
  padding: 10px 20px 5px 20px;
  .conte-bt {
    display: flex;
    font-size: 13px;
    font-weight: 600;
    line-height: 25px;
    color: #000;
    padding-bottom: 10px;
  }
  .conte-input {
    display: flex;
  }
  .an {
    font-size: 18px;
    padding-left: 15px;
    cursor: pointer;
  }
  .ri-add-box-fill:before {
    font-size: 18px;
    color: #409eff;
  }
  .ri-checkbox-indeterminate-line:before {
    font-size: 18px;
    color: #f56c6c;
    padding-left: 5px;
  }
  .conte1-disp {
    display: flex;
  }
}
</style>
