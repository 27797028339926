var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 330px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              staticClass: "table eleChange",
              attrs: {
                "element-loading-text": "拼命加载中",
                border: "",
                data: _vm.list,
                height: "100%",
                "header-cell-class-name": "table-header",
                "row-style": { height: "30px" },
                "cell-style": { padding: "2px" },
                "row-class-name": _vm.rowStyle
              },
              on: {
                "selection-change": _vm.setSelectRows,
                "row-click": _vm.handleRowClick
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "show-overflow-tooltip": "",
                  fixed: "left",
                  type: "selection",
                  width: "55"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "询价编号",
                  align: "center",
                  prop: "inquiryCode"
                }
              }),
              _c(
                "el-table-column",
                { attrs: { label: "基础信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      align: "center",
                      label: "日期"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "customer", align: "center", label: "客户" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salesman",
                      align: "center",
                      label: "业务员"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "提货地址", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryPickList",
                      align: "center",
                      label: "起运市"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryPickList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.city) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryPickList",
                      align: "center",
                      label: "起运区"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryPickList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.areas) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100px",
                      prop: "inquiryPickList",
                      align: "center",
                      label: "起运街道"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryPickList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.street) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDispatchList",
                      align: "center",
                      label: "具体地址",
                      width: "220px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryPickList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.address) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "送货地址", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDispatchPortList",
                      align: "center",
                      label: "起运港"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(
                            scope.row.inquiryDispatchPortList,
                            function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "text-align": "center" }
                                },
                                [_vm._v(" " + _vm._s(item.portName) + " ")]
                              )
                            }
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "货物信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryGoodsList",
                      align: "center",
                      label: "柜型柜量"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryGoodsList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.cabinetType) +
                                    "*" +
                                    _vm._s(item.cabinetNum) +
                                    " "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "weight", align: "center", label: "重量" }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "备注",
                    width: "350",
                    "show-overflow-tooltip": "",
                    align: "center",
                    prop: "remark"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _vm._v(" 备注 "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "询价信息的补充",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-warning" })]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "el-table-column",
                { attrs: { label: "报价信息", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      align: "center",
                      "class-name": "myCell",
                      prop: "inquiryDetailList",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.productCode) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      "class-name": "myCell",
                      align: "center",
                      label: "产品专员"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.productCommissioner) + " "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      "class-name": "myCell",
                      align: "center",
                      label: "起运区"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.productPickAreas) + " "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      "class-name": "myCell",
                      align: "center",
                      label: "起运街道"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.productPickStreet) + " "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "inquiryDetailList",
                        align: "center",
                        label: "总标准价",
                        "class-name": "myCell"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.inquiryDetailList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "myCellDiv",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.productQuotation) + " "
                                  )
                                ]
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 总标准价 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "产品报价",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "inquiryDetailList",
                        align: "center",
                        label: "总销售价",
                        "class-name": "myCell"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._l(scope.row.inquiryDetailList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "myCellDiv",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.customerQuotation) + " "
                                  )
                                ]
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v(" 总销售价 "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "客户报价",
                                placement: "top"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-warning" })]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "成交状态",
                      "class-name": "myCell"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                item.dealType == "未成交"
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "danger" } },
                                          [_vm._v(_vm._s(item.dealType))]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.dealType == "已成交"
                                  ? _c(
                                      "span",
                                      [
                                        _c("el-tag", [
                                          _vm._v(_vm._s(item.dealType))
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "物流方案",
                      "class-name": "myCell",
                      width: "90px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.logisticsType) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "标准价明细",
                      "class-name": "myCell",
                      width: "300px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: item.standardDesc,
                                      placement: "top",
                                      effect: "dark"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "-webkit-box",
                                          "text-overflow": "ellipsis",
                                          overflow: "hidden",
                                          "-webkit-line-clamp": "2",
                                          "-webkit-box-orient": "vertical",
                                          "white-space": "pre-line"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.standardDesc) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "是否含税",
                      "class-name": "myCell",
                      width: "90px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.includingTaxes == 0
                                        ? "不含税"
                                        : item.includingTaxes == 1
                                        ? "含税"
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "时效",
                      "class-name": "myCell",
                      width: "90px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.ageing) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "附加费",
                      "class-name": "myCell",
                      width: "90px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" " + _vm._s(item.surcharge) + " ")]
                            )
                          })
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inquiryDetailList",
                      align: "center",
                      label: "备注",
                      "class-name": "myCell",
                      width: "200px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return _vm._l(scope.row.inquiryDetailList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "myCellDiv",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: item.remark,
                                      placement: "top",
                                      effect: "dark"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "-webkit-box",
                                          "text-overflow": "ellipsis",
                                          overflow: "hidden",
                                          "-webkit-line-clamp": "2",
                                          "-webkit-box-orient": "vertical",
                                          "white-space": "pre-line"
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.remark) + " ")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "class-name": "myCell",
                  fixed: "right",
                  label: "询价来源",
                  width: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.sourceType == 1
                                ? "web端询价"
                                : "手机端询价"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "成交操作",
                  fixed: "right",
                  width: "100",
                  "class-name": "myCell"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.inquiryDetailList, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "myCellDiv",
                            staticStyle: { "text-align": "center" }
                          },
                          [
                            item.dealTypeId == 6
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "0px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDiao(
                                          item.id,
                                          "typeOne"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 变更未成交 ")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "0px",
                                      color: "red"
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDiao(
                                          item.id,
                                          "typeTwo"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" 变更已成交 ")]
                                )
                          ],
                          1
                        )
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createBy",
                  align: "center",
                  label: "创建人",
                  fixed: "right",
                  width: "60"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "70",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdata(row, "update")
                              }
                            }
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdata(row, "copy")
                              }
                            }
                          },
                          [_vm._v(" 复制 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }