<template>
  <div>
    <div style="height: calc(100vh - 330px)">
      <el-table ref="tableSort" v-loading="listLoading" border :cell-style="{ padding: '2px' }" class="table eleChange"
        :data="list" element-loading-text="拼命加载中" header-cell-class-name="table-header" height="100%"
        :row-class-name="rowStyle" :row-style="{ height: '30px' }" @row-click="handleRowClick"
        @selection-change="setSelectRows">
        <el-table-column align="center" fixed="left" type="selection" width="55" />
        <el-table-column align="center" label="询价编号" prop="inquiryCode" />
        <el-table-column align="center" label="基础信息">
          <el-table-column align="center" label="日期" prop="createTime" />
          <el-table-column align="center" label="客户" prop="customer" />
          <el-table-column align="center" label="业务员" prop="salesman" />
        </el-table-column>
        <el-table-column align="center" label="提货地址">
          <el-table-column align="center" label="起运市" prop="inquiryPickList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryPickList" :key="index" style="text-align: center">
                {{ item.city }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="起运区" prop="inquiryPickList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryPickList" :key="index" style="text-align: center">
                {{ item.areas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="起运街道" prop="inquiryPickList" width="100px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryPickList" :key="index" style="text-align: center">
                {{ item.street }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="具体地址" prop="inquiryDispatchList" width="220px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryPickList" :key="index" style="text-align: center">
                {{ item.address }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="送货地址">
          <el-table-column align="center" label="目的市" prop="inquiryDispatchList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDispatchList" :key="index" style="text-align: center">
                {{ item.city }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="目的区" prop="inquiryDispatchList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDispatchList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.areas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="目的街道" prop="inquiryDispatchList" width="100px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDispatchList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.street }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="具体地址" prop="inquiryDispatchList" width="220px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDispatchList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.address }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="货物信息">
          <el-table-column align="center" label="重量" prop="weight" />
          <el-table-column align="center" label="体积" prop="volume" />
          <el-table-column align="center" label="包装方式" prop="packagingMethod" />
        </el-table-column>
        <el-table-column align="center" label="备注" prop="remark" show-overflow-tooltip width="350">
          <template slot="header">
            备注
            <el-tooltip content="询价信息的补充" effect="dark" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="报价信息">
          <el-table-column align="center" class-name="myCell" label="产品编号" prop="inquiryDetailList" width="120">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="产品专员" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productCommissioner }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="起运区" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productPickAreas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="起运街道" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productPickStreet }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="目的区" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productDispatchAreas }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="目的街道" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productDispatchStreet }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="总标准价" prop="inquiryDetailList">
            <template slot="header">
              总标准价
              <el-tooltip content="产品报价" effect="dark" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.productQuotation }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="总公里数" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.logisticsDistance }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="车型" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.truckType }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="总销售价" prop="inquiryDetailList">
            <template slot="header">
              总销售价
              <el-tooltip content="客户报价" effect="dark" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.customerQuotation }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="成交状态" prop="inquiryDetailList">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                <!-- <div v-if="item.dealType == '已成交'" style="color: #3cb371">
                {{ item.dealType }}
              </div>
              <div v-else>
                {{ item.dealType }}
              </div> -->
                <span v-if="item.dealType == '未成交'">
                  <el-tag type="danger">{{ item.dealType }}</el-tag>
                </span>
                <span v-if="item.dealType == '已成交'">
                  <el-tag>{{ item.dealType }}</el-tag>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="物流方案" prop="inquiryDetailList" width="90px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.logisticsType }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" class-name="myCell" label="标准价明细" prop="inquiryDetailList" width="300px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                <el-tooltip :content="item.standardDesc" effect="dark" placement="top">
                  <span style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    ">
                    {{ item.standardDesc }}
                  </span>
                </el-tooltip>
                <!-- {{ item.standardDesc }} -->
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" class-name="myCell" label="是否含税" prop="inquiryDetailList" width="90px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{
                  item.includingTaxes == 0
                    ? '不含税'
                    : item.includingTaxes == 1
                      ? '含税'
                      : ''
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="myCell" label="时效" prop="inquiryDetailList" width="90px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                {{ item.ageing }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
          prop="inquiryDetailList"
          align="center"
          label="附加费"
          class-name="myCell"
          width="90px"
        >
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.inquiryDetailList"
              :key="index"
              style="text-align: center"
              class="myCellDiv"
            >
              {{ item.surcharge }}
            </div>
          </template>
        </el-table-column> -->
          <el-table-column align="center" class-name="myCell" label="备注" prop="inquiryDetailList" show-overflow-tooltip
            width="200px">
            <template slot-scope="scope">
              <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
                style="text-align: center">
                <el-tooltip :content="item.remark" effect="dark" placement="top">
                  <span style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    ">
                    {{ item.remark }}
                  </span>
                </el-tooltip>
                <!-- <el-tooltip
                class="item"
                effect="dark"
                placement="top"
                v-if="!isShowTooltip"
              >
                <div v-html="ToBreak(item.remark, 50)" slot="content"></div>
                <div class="oneLine" @mouseover="onMouseOver">
                  {{ item.remark }}
                </div>
              </el-tooltip>
              <div
                class="oneLine"
                @mouseover="onMouseOver"
                v-if="isShowTooltip"
              >
                {{ item.remark }}
              </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" class-name="myCell" fixed="right" label="询价来源" width="100">
          <template slot-scope="scope">
            {{ scope.row.sourceType == 1 ? 'web端询价' : '手机端询价' }}
          </template>
        </el-table-column>
        <el-table-column align="center" class-name="myCell" fixed="right" label="成交操作" width="100">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.inquiryDetailList" :key="index" class="myCellDiv"
              style="text-align: center">
              <el-button v-if="item.dealTypeId == 6" style="padding: 0px" type="text"
                @click="handleDiao(item.id, 'typeOne')">
                变更未成交
              </el-button>
              <el-button v-else style="padding: 0px; color: red" type="text" @click="handleDiao(item.id, 'typeTwo')">
                变更已成交
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="创建人" prop="createBy" width="60" />
        <el-table-column align="center" fixed="right" label="操作" width="70">
          <template #default="{ row }">
            <el-button type="text" @click="handleUpdata(row, 'update')">
              修改
            </el-button>
            <el-button type="text" @click="handleDelete(row)">删除</el-button>
            <el-button type="text" @click="handleUpdata(row, 'copy')">
              复制
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image class="vab-data-empty" :src="require('@/assets/empty_images/data_empty.png')" />
        </template>
      </el-table>
    </div>
    <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize"
      :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
  </div>
</template>
<script>
import {
  bulkCargoCheckDetailStatus,
  bulkCargoDelete,
  bulkCargoList,
  bulkCargoUpdateDetailStatus,
} from '@/api/inquiryManagement';
export default {
  props: {
    form: Object,
    handleAdd: Function,
  },
  data() {
    return {
      listLoading: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      height: this.$baseTableHeight(2) - 50,
      list: [],
      idList: [],
      isShowTooltip: true,
      ToBreak(val, num) {
        var str = val
        var number = num //间隔换行的字符数
        var bytesCount = 0 //定义一个变量记录字符串总字节长度
        var s = '' //定义一个空字符串用来接收重新拼接换行之后的字符串
        var pattern = new RegExp('[\u4E00-\u9FA5]+') //汉字的正则表达式
        for (var i = 0; i < str.length; i++) {
          //遍历原字符串
          var c = str.charAt(i) //拿到每一个下标对应的值
          // 统计字符串的字符长度
          if (pattern.test(c)) {
            bytesCount += 2 //如果是汉字长度+2
          } else {
            bytesCount += 1 //不是汉字长度+1
          }
          s += str.charAt(i) //重新拼接字符串
          // 换行
          if (bytesCount >= number) {
            //在指定的长度位置插入换行标签
            s = s + '<br>'
            // 重置
            bytesCount = 0 //保证在下一个相同长度后继续换行
          }
        }
        return s
      },
      queryForm: {
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  mounted() {
    // this.handleQuery()
  },
  beforeMount() {
    window.addEventListener('resize', this.handleHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleHeight)
  },
  methods: {
    handleHeight() {
      this.height = this.$baseTableHeight(3) - 30
    },
    onMouseOver(e) {
      var target = e.target
      let textLength = target.clientWidth
      let containerLength = target.scrollWidth
      if (textLength < containerLength) {
        // 溢出了
        this.isShowTooltip = false
      } else {
        this.isShowTooltip = true
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = val
      this.handleQuery()
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val
      this.handleQuery()
    },
    setSelectRows(val) {
      this.idList = []
      for (let index = 0; index < val.length; index++) {
        this.idList.push(val[index].id)
      }
      this.$emit('customEvent', this.idList)
    },
    rowStyle({ row }) {
      var arr = this.idList
      for (let i = 0; i < arr.length; i++) {
        if (row.id === arr[i]) {
          return 'rowStyle'
        }
      }
    },
    handleRowClick(row, column) {
      if (column && column.label == '操作') {
        return
      }
      this.$refs.tableSort.toggleRowSelection(row)
    },
    async handleQuery() {
      this.listLoading = true
      this.$set(this.form, 'pageNum', this.queryForm.pageNum)
      this.$set(this.form, 'pageSize', this.queryForm.pageSize)
      const res = await bulkCargoList(this.form)
      this.list = res.data.pageList
      this.total = res.data.totalSize
      this.listLoading = false
    },
    handleUpdata(row, type) {
      this.handleAdd(row, type)
      this.$refs.tableSort.toggleRowSelection(row, true)
      // this.$refs['edit'].showEdit(row)
    },
    async handleDiao(row, type) {
      let data = {
        id: row,
        dealTypeId: 7,
        dealType: '未成交',
      }
      if (type == 'typeOne') {
        const rew = await bulkCargoCheckDetailStatus(data)
        console.log(rew)
        if (rew.data) {
          const res = await bulkCargoUpdateDetailStatus(data)
          this.handleQuery()
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } else {
          this.$baseConfirm(
            '已有成交报价!若确认该报价已成交，则原已成交的报价会变为未成交',
            null,
            async () => {
              const res = await bulkCargoUpdateDetailStatus(data)
              this.handleQuery()
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            }
          )
        }
      } else {
        this.$set(data, 'dealTypeId', 6)
        this.$set(data, 'dealType', '已成交')
        const rew = await bulkCargoCheckDetailStatus(data)
        if (rew.data) {
          const res = await bulkCargoUpdateDetailStatus(data)
          this.handleQuery()
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        } else {
          this.$baseConfirm(
            '已有成交报价!若确认该报价已成交，则原已成交的报价会变为未成交',
            null,
            async () => {
              const res = await bulkCargoUpdateDetailStatus(data)
              this.handleQuery()
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            }
          )
        }
        // const res = await bulkCargoUpdateDetailStatus(data)
        // this.handleQuery()
        // this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
      }
    },
    async handleDelete(row) {
      this.$refs.tableSort.toggleRowSelection(row, true)
      this.$baseConfirm('确认删除此条询价吗', '警告', async () => {
        const res = await bulkCargoDelete({ id: row.id })
        this.handleQuery()
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .cell {
    padding: 0px;
  }
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.myCell:not(.table-header),
.myCell:not(.table-header) .cell {
  padding: unset !important;
}

.myCell .myCellDiv {
  border-bottom: 1px solid #ebeef5;
  // color: #00ff55b4;
  // padding: 20px 12px;
  height: 60px;
  // display: table-cell;
  // text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}

.myCell .cell .myCellDiv:last-child {
  border-bottom: unset;
}

::v-deep .rowStyle {
  background-color: #e8f7fd !important;
  color: #1890ff;
}
</style>
